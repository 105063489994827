a {
    color: #58595b !important;
}

a,
a:hover {
    text-decoration: none !important;
}

@each $name,
$color in $theme-text-colors {
    // Base color
    .text-#{$name} {
        color: $color !important;
    }
    // Inverse color
    @if (theme-light-color($name)) {
        .text-inverse-#{$name} {
            color: theme-inverse-color($name) !important;
        }
    }
    // Light colors
    @if (theme-light-color($name)) {
        .text-light-#{$name} {
            color: theme-light-color($name) !important;
        }
    }
    // Text hover
    a.text-hover-#{$name},
    .text-hover-#{$name} {
        &:hover {
            color: $color !important;
            i {
                color: $color !important;
            }
        }
    }
    // Parent hover
    .hoverable {
        &:hover {
            a.text-hover-#{$name},
            .text-hover-#{$name} {
                color: $color !important;
                i {
                    color: $color !important;
                }
            }
        }
    }
}

.br {
    display: block;
}

.text-small {
    font-size: 0.7rem;
}

.text-xsmall {
    font-size: 0.6rem;
}

.text-large {
    font-size: 1.2rem;
}

.text-xlarge {
    font-size: 1.3rem;
}

.text-underline {
    text-decoration: underline;
}

.text-thin {
    font-weight: 100;
}

.text-light {
    font-weight: 300;
}

.text-regular {
    font-weight: normal;
}

.text-medium {
    font-weight: 500;
}

.text-bold {
    font-weight: bold;
}

.text-bolder {
    font-weight: 700;
}

.text-extrabold {
    font-weight: 800;
}

.text-extrabolder {
    font-weight: 850;
}

.text-extrablack {
    font-weight: 900;
}

blockquote {
    font-weight: 500;
    padding: 10px;
    border: 1px dashed #666666;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
}

h6 {
    font-size: 15px !important;
}

small {
    font-size: 11px !important;
    display: block;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.before-title {
    padding: 30px 0px 15px 0px;
    &.simple {
        p {
            font-size: 14px;
            font-weight: bold;
            &::before {
                display: none;
            }
        }
    }
    &.small {
        p {
            font-size: 15px;
        }
    }
    p {
        display: flex;
        font-size: 18px;
        line-height: 22px;
        font-weight: 300;
        &::before {
            content: "";
            width: 5px;
            height: 22px;
            right: 15px;
            border-radius: 5px;
            background-color: $primary;
            margin-left: 5px;
        }
    }
    @media (max-width: 992px) {
        p {
            font-size: 14px;
        }
    }
}
.pagination {
    margin-bottom: 0px;
    padding: 0px;
    margin-top: 10px;
    li {
        width: 30px;
        height: 30px;
        border-radius: $border-radius;
        margin-right: 5px;
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            color: $black !important;
            padding: 3px 0px 0px;
        }
        &:nth-child(2) {
            margin-right: 0;
        }
        &.selected {
            border: 1px solid $dark;
        }
    }
    &.styled {
        li {
            border-radius: 50px;
            border: 2px solid $gray-800;
            width: 33px;
            height: 33px;
            &.selected {
                background-color: $gray-800;
                a {
                    color: $white !important;
                }
            }
            &:nth-child(2) {
                margin-right: 5px;
            }
            a {
                color: $gray-800 !important;
                i {
                    font-size: 13px;
                }
                &[aria-label='Previous page'],
                &[aria-label='Next page'] {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                }
            }
        }
    }
}
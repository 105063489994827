#category {
    .best-selling-banner {
        margin-top: 12px;

        .card-body {
            header {
                margin-bottom: 10px;
                font-weight: 700;
                color: $text-dark;
                font-size: 14px;
            }

            a {
                margin-bottom: 12px;
                display: flex;

                img {
                    border-radius: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .category-name {
        display: flex;
        align-items: center;

        small {
            margin-right: 5px;
        }
    }

    .sorting-list {
        .card-body {
            display: flex;
            align-items: center;
            padding: 17px 10px;

            .item {
                margin-left: 10px;
                padding: 0px 5px;
                position: relative;

                &.active {
                    &::after {
                        content: "";
                        height: 5px;
                        width: 100%;
                        background-color: $primary;
                        position: absolute;
                        top: -17px;
                        right: 0;
                        border-radius: 0 0 10px 10px;
                    }
                }
            }
        }
    }

    .find-box {
        .card-body {
            padding: 15px;

            .question {
                display: flex;
                align-items: center;

                .actions {
                    display: flex;
                    align-items: center;

                    .item {
                        height: 30px;
                        width: 70px;
                        margin-left: 4px;
                        border: 1px solid $dark;
                        border-radius: 2px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:last-child {
                            margin-left: 0;
                        }

                        &:hover,
                        &.active {
                            background-color: $primary;
                            border-color: $primary;
                            color: $white;
                        }
                    }
                }
            }

            .content {
                border-top: 1px solid $border-color;
                padding-top: 15px;
                margin-top: 15px;

                button {
                    width: 150px;
                    display: block;
                    margin: auto;
                }
            }
        }
    }

    .related-articles {
        .blogs {
            .blog {
                .card {
                    .card-body {
                        padding: 15px;
                        font-size: 12px;

                        a {
                            color: $dark;

                            img {
                                border-radius: $border-radius;
                                width: 100%;
                                height: auto;
                            }

                            .title {
                                margin-bottom: 10px !important;
                                font-weight: bold;
                                color: $black;
                            }

                            &:hover {
                                color: $black !important;
                            }
                        }
                    }
                }
            }
        }

        .see-all {
            display: block;
            margin: auto;
            border-radius: 30px;
            font-weight: 700;
            width: 100%;
        }
    }

    .pagination-box {
        .card-body {
            padding: 20px 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .change-page {
                width: 78px;

                div {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    color: $text-muted;
                }
            }
        }
    }

    .related-questions {
        .card {
            .card-body {
                .accordion {
                    color: $dark;

                    .accordion-item {
                        border-right: 0px;
                        border-left: 0px;
                        border-top: 0px;

                        &:last-child {
                            border-bottom: 0px;
                        }

                        .accordion-header {
                            button {
                                font-size: 14px;
                                padding: 20px 0px 20px 10px;
                            }
                        }

                        .accordion-body {
                            padding: 0px 7px 1rem;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .description-box {
        .card {
            .card-body {
                max-height: 210px;
                overflow: auto;

                p {
                    padding-bottom: 10px !important;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .category-name {
            padding: 10px 0px;
        }
    }

    @media (max-width: 576px) {
        .category-name {
            justify-content: space-between;
        }

        .products {
            .main-product {
                &.responsive {
                    .card-body {
                        padding: 0;
                    }

                    .img-box {
                        padding-left: 0;
                    }

                    .img-holder {
                        height: 200px;
                        margin: 0;
                        border-radius: 0 4px 4px 0;
                    }

                    .side-space {
                        padding-right: 0;
                        padding-top: 15px;
                        padding-bottom: 15px;

                    }

                    .customer {
                        display: none;
                    }
                }
            }
        }

        .find-box {
            .card-body {
                .question {
                    flex-flow: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .actions {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

#sort-modal {
    .modal-body {
        padding: 1rem 35px 20px 20px;

        .sort-list {
            .form-check {
                padding-bottom: 15px;
                padding-top: 15px;
                border-bottom: 1px solid $border-color;

                &:last-child {
                    border: none;
                }
            }
        }
    }
}

#share-product-modal {
    .modal-body {
        .socials {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 20px 0px;

            a {
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $gray-200;
                border-radius: $border-radius;
                box-shadow: 0 0 4px 0 rgb(0 0 0 / 16%);
                margin: 0px 5px 10px;

                img {
                    width: 48px;
                    height: auto;
                }

                span {
                    line-height: 47px;
                    font-weight: 700;
                    font-size: 21px;
                    color: #845f5f;
                }
            }
        }

        .link {
            display: flex;
            align-items: center;

            input {
                height: 34px;
                border-radius: 8px;
                border: solid 1px #c6c6c6;
                width: 100%;
                padding: 0 10px;
                position: relative;
                direction: ltr;

                &:focus-visible {
                    outline: 1px solid $primary;
                }
            }

            span {
                width: 60px;
                min-width: 60px;
                height: 34px;
                border-radius: 8px;
                border: solid 1px #c6c6c6;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }
}
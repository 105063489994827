input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

.form-check {
    padding-left: 0px !important;
    padding-right: 1.25rem;
    input {
        float: right !important;
        margin-left: 0 !important;
        margin-right: -1.25rem;
        width: 20px;
        height: 20px;
    }
    label {
        margin-right: 11px;
        margin-top: 2px;
    }
}

.form-switch {
    padding-left: 2.5rem !important;
    padding-right: 0px !important;
    input {
        float: left !important;
        width: 43px !important;
        height: 25px;
        margin-top: 0;
        margin-left: -2.5rem !important;
        margin-right: 0px !important;
    }
    label {
        margin-right: 0;
        margin-top: 0;
    }
}

.form-check {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    label {
        color: $dark;
    }
}

.form-group {
    margin-bottom: 15px !important;
    label {
        font-weight: normal;
        margin-bottom: 5px;
        &.bold {
            font-weight: bold;
        }
    }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    padding-left: calc(1.5em + 0.75rem) !important;
    padding-right: 0.75rem !important;
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem) !important;
}

.form-select {
    background-position: left 0.75rem center !important;
    padding: 0.375rem 0.75rem 0.375rem 2.25rem !important;
    font-size: 13px !important;
}

.form-control {
    background-color: #f2f3f7 !important;
    font-size: 13px !important;
    border-radius: $border-radius 0 0 $border-radius !important;
    &:focus {
        box-shadow: none !important;
        border-color: #c9c9c9 !important;
    }
    &.right-border {
        transition: all ease 0.25s;
        &:focus {
            border-right-width: 5px;
        }
    }
    &.red-focus {
        &:focus {
            border-color: $primary !important;
        }
    }
    &.form-control-solid {
        border-radius: $border-radius !important;
        background-color: $white !important;
        &.round {
            border-radius: calc(#{$border-radius} * 2) !important;
        }
        &.big {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
        }
    }
    &:disabled,
    &[readonly] {
        background-color: #efefef !important;
    }
}

.no-resize {
    resize: none;
}

input[type='number'],
input[type='email'],
.number {
    text-align: left;
    direction: ltr;
    &::placeholder {
        text-align: right;
        direction: rtl;
    }
}

input[type='number'] {
    -moz-appearance: textfield !important;
    text-align: left;
    direction: ltr;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

.react-select {
    >div {
        min-height: 37px;
    }
}
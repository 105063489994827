.dropzone {
    background-color: #f4f3f8;
    position: relative;
    text-align: center;
    padding: 30px 15px;
    border: 1px dashed #d2d1d6;
    border-radius: 4px;
    margin-top: 20px;
    .input-text {
        .title {
            padding: 20px 0px;
            font-weight: bold;
        }
    }
    .thump-box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .thump {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background-color: $white;
            padding: 12px;
            .item {
                display: flex;
                min-width: 0;
                overflow: hidden;
                width: 50px;
                height: 50px;
                img {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
            table {
                width: 100%;
                tbody {
                    tr {
                        td {
                            &:nth-child(1) {
                                widows: 20%;
                            }
                        }
                    }
                }
            }
            .icons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                i {
                    font-size: 20px;
                    margin-left: 10px;
                    cursor: pointer;
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
.valuation {
    background: $gray-300;
    padding: 30px 0 5px;
    margin-top: 70px;

    .item {
        display: flex;
        flex-flow: column;
        align-items: center;

        p {
            text-align: center;
            color: $gray-500;
            margin-top: 15px;
            line-height: 20px;
        }
    }
}

footer {
    ul {
        list-style: none;
        padding: 0;
    }

    .footer-box {
        background: $gray-600;
        padding: 40px 0;
        color: $gray-100;
    }

    .quick-access {
        .menu-item {
            position: relative;
            z-index: 2;

            li {
                a {
                    margin-bottom: 17px;
                    position: relative;
                    display: inline-block;
                    padding-left: 15px;
                    color: $gray-400 !important;
                    line-height: 13px;

                    &:hover {
                        color: #fff !important;
                    }
                }
            }
        }

        .box-title {
            position: relative;
            font-size: 18px;
            font-weight: 700;
            height: 27px;
            line-height: 25px;
            margin-bottom: 20px;
        }

        .newsletter {
            .inputcontainer {
                display: flex;
                align-items: flex-start;
            }

            .footer-newsletter-box {
                width: 70%;

                #FooterNewsLetter {
                    border: 1px solid #b3b4b6;
                    height: 40px;
                    border-radius: 2.5px !important;
                    padding: 0 15px !important;
                    margin: 0;
                    outline: 0;
                    display: inline-table;
                    overflow: hidden;
                    width: 100%;
                    vertical-align: middle;
                    background: #414244 !important;
                    color: #e1e2e4 !important;

                    &::placeholder {
                        color: #989899 !important;
                        text-align: left;
                    }
                }
            }

            .nl-btn {
                background-color: #505153;
                height: 40px;
                color: #fff;
                display: inline-table;
                vertical-align: middle;
                line-height: 38px;
                padding: 0 15px;
                border-radius: 2.5px;
                border: 1px solid #b3b4b6;
                position: relative;

                &:hover {
                    background-color: #fff;
                    cursor: pointer;
                    color: #444;
                }
            }

            p {
                margin-bottom: 15px !important;
            }
        }

        .social-media {
            margin-top: 30px;

            p {
                margin-bottom: 15px !important;
                line-height: 18px;
            }

            ul {
                display: flex;

                li {
                    margin-left: 10px;

                    a {
                        img {
                            width: 23px;
                        }

                        i {
                            color: $white;
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }

    .support-box {
        position: relative;
        background-color: $gray-700;
        margin: 40px 0;

        .item {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 19px;
            padding-top: 19px;

            i {
                color: #c6c6c6;
                margin-left: 10px;
                font-size: 18px;
            }

            &:first-child {
                font-size: 18px;
            }

            a {
                color: inherit !important;
            }
        }
    }

    .footer-bottom {
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            li {
                &.imglnk {
                    cursor: pointer;
                    width: 18%;
                    height: 120px;
                    border-radius: 10px;
                    border: solid 1px #efefef;
                    margin: 0 1%;
                    float: left;
                    padding: 5px 2px;
                    text-align: center;
                    background-color: #fff;
                    filter: grayscale(100%);

                    a,
                    span {
                        background-image: url(https://static.vitrinnet.com/img/footerlogos2.jpg);
                        width: 76px;
                        height: 100px;
                        display: inline-block;
                        background-size: 320px 204px;
                    }

                    &.anjoman a {
                        background-position: -160px 0;
                    }

                    &.etehadie span {
                        background-position: -80px 0;
                    }

                    &.enamad span {
                        background-position: 2px 0;
                    }

                    &.daneshbonyan a {
                        background-position: -239px 0;
                    }

                    &.samandehi span {
                        background-position: -237px -102px;
                    }
                }
            }
        }

        .about {
            margin-bottom: 10px;

            img {
                max-width: 180px;
            }

            p {
                font-size: 13px;
                text-align: justify;
                width: 100%;
                color: #cecece;
                display: inline-block;
                margin: 0 0 15px;
                line-height: 20px;

                a {
                    color: #a9a9a9 !important;

                    &:hover {
                        color: $primary !important;
                    }
                }
            }

            .openmoredesc {
                margin-right: 5px;
                display: inline-block;

                &:hover {
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }

    .copy-right {
        font-size: 12px;
        color: #c6c6c6;
        margin-top: 15px;
        font-family: tahoma;
    }

    @media (max-width: 1199px) {
        .quick-access {
            .news-letter-box {
                max-width: 100%;
                margin-top: 140px;
                margin-bottom: 38px;
            }

            .social-media {
                margin-top: 0px;
            }
        }

        .support-box {
            position: absolute;
            width: 100%;
            margin: 0;
            margin-top: -240px;

            .item {
                &:first-child {
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .support-box {
            .item {
                &:first-child {
                    font-size: 13px;
                }
            }
        }

        .footer-bottom {
            ul {
                li {
                    &.imglnk {
                        width: 100px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .quick-access {
            .news-letter-box {
                margin-top: 210px;
                text-align: center;
            }

            .social-media {
                ul {
                    justify-content: center;
                }
            }

            .newsletter {
                .inputcontainer {
                    justify-content: center;
                }
            }
        }

        .support-box {
            margin-top: -437px;
        }

    }

    @media (max-width: 576px) {
        .footer-bottom {
            ul {
                li {
                    &.imglnk {
                        width: 27%;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

.share-section {
    .share-section-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 15px;
        right: 20px;
        background: $success;
        border-radius: 10px;
        width: 57px;
        height: 57px;
        line-height: 57px;
        text-align: center;
        cursor: pointer;
        z-index: 10;
        animation: pulse2 1.5s infinite, animateScale 1.5s infinite;
        transition: right 0.3s;

        i {
            font-size: 20px;
            color: $white;
            animation: animateRotate 1.5s infinite;
            display: block;
            position: relative;
        }
    }

    .share-section-content {
        background: #fff;
        padding: 15px 15px 5px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        position: fixed;
        bottom: -50px;
        right: 15px;
        z-index: 500;
        width: 360px;
        box-shadow: 0 0 3px #ccc;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        z-index: 10;

        &.active {
            opacity: 1;
            visibility: visible;
            bottom: 0;
            z-index: 400;
        }

        .item {
            font-size: 16px;
            color: #000;
            border-bottom: 1px solid #dcdee3;
            padding: 15px 0;
            cursor: pointer;

            a {
                display: flex;
                align-items: center;

                small {
                    color: #666;
                    font-size: 11px;
                    margin-right: 4px;
                }

                i {
                    margin-left: 4px;
                }

                &:hover {
                    color: $primary !important;
                }
            }

            &:last-child {
                border: none;
            }
        }
    }

    @media (max-width: 768px) {
        .share-section-content {
            width: 100%;
            right: 0;
        }
    }
}

.share-section-mobile {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 20;
    font-size: 15px;
    display: none;

    @media (max-width: 576px) {
        .share-section-mobile-content {
            font-size: 12px;
            display: flex;

            a {
                color: $white !important;

                i {
                    margin-left: 4px;
                    font-size: 15px;
                }
            }
        }
    }
}

.share-section-mobile .consultation-request,
.share-section-mobile .connect-whatsApp,
.share-section-mobile .phone-consultancy {
    text-align: center;
    border-bottom: 0;
    height: 60px;
    line-height: 60px;
}

.share-section-mobile .phone-consultancy {
    background: #00a500;
    color: #fff;
    border-top-right-radius: 8px;
}

.share-section-mobile .connect-whatsApp {
    background: #017f01;
    color: #fff;
}

.share-section-mobile .consultation-request {
    background: #026802;
    color: #fff;
    border-top-left-radius: 8px;
}

.share-section-mobile #collapse-share-section {
    height: auto;
}

.share-section .share-section-button.show-button {
    right: 15px;
}

.share-section .title {
    color: #a5a5a5;
    border-bottom: 1px solid #dcdee3;
    padding: 15px 0;
    position: relative;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.share-section .title:before {
    content: "";
    position: absolute;
    top: 0;
    width: 50px;
    height: 3px;
    background: #707070;
    right: 50%;
    margin-right: -25px;
}

.share-section .consultation-request-button {
    border-bottom: 0;
}

.share-section .phone-consultancy a:hover,
.share-section .connect-whatsApp a:hover,
.share-section .consultation-request-button:hover,
.consultation-request-button a:hover {
    color: #f00;
}

.share-section .phone-consultancy i,
.share-section .consultation-request-button i,
.share-section .connect-whatsApp i {
    margin-left: 5px;
    vertical-align: middle;
}

.share-section .consultation-request {
    position: relative;
}

.share-section .consultation-request-form #MainPriceRequest {
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    width: 100%;
    margin-top: -3px;
}

.share-section .consultation-request-form .btn-v3 {
    background: #222;
}

.share-section .consultation-request-form .btn-v3:hover {
    background: #555;
}

.share-section .consultation-request-form select {
    background: #fff;
}

@media (max-width: 554px) {
    .share-section .consultation-request-form #MainPriceRequest {
        height: 200px;
        overflow-y: scroll;
    }
}

@media (max-width: 380px) {

    .share-section .phone-consultancy i,
    .share-section .consultation-request-button i,
    .share-section .connect-whatsApp i {
        margin-left: 2px;
    }
}
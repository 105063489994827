#free-consultation {
    .card {
        .card-body {
            padding: 40px;

            @media (max-width: 768px) {
                .text-content {
                    padding-bottom: 15px;
                    margin-bottom: 15px !important;
                    border-bottom: 1px solid $border-color;
                }
            }
        }
    }
}
$custom_dark: #4E535A;
$custom_black: #1A1C1E;
$custom_white: #ffffff;
$custom_gray_100: #e1e2e4;
$custom_gray_200: #efefef;
$custom_gray_300: #e5e5e5;
$custom_gray_400: #d5d6d8;
$custom_gray_500: #a9a9a9;
$custom_gray_600: #505153;
$custom_gray_700: #414244;
$custom_gray_800: #446084;
$custom_gray_900: #282B34;
$custom_primary: #e2374d;
$custom_dark_primary: #e1384d4f;
$custom_success: #00a500;
$custom_dark_success: #00a500;
$custom_info: #2fc6db;
$custom_dark_info: #2fc6db;
$custom_warning: #f9a643;
$custom_dark_warning: #956428;
$custom_danger: #e2374d;
$custom_dark_danger: #e1384d4f;

.nav-tabs {
    padding: 15px 15px 0;
    width: 100%;
    overflow: auto;
    flex-wrap: nowrap !important;
    padding-bottom: 1px;
    .nav-item {
        min-width: max-content;
        .nav-link {
            padding: 0.5rem 1rem;
            border: none !important;
            border-bottom: 2px solid transparent !important;
            color: $text-muted;
            &:hover {
                font-weight: bold;
                color: #000;
                border: none !important;
                border-bottom: 2px solid transparent !important;
            }
            &.active {
                border-bottom: 2px solid $primary !important;
                color: #000;
                font-weight: bold;
            }
            .count {
                width: 23px;
                height: 23px;
                border-radius: 50%;
                background-color: $primary;
                color: $white;
                font-size: 12px;
                margin-right: 5px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.tab-content {
    padding: 15px 15px 30px;
}
@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebBold.eot');
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebBold.eot?#iefix') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/iran-yekan/fonts/woff/IRANYekanWebBold.woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/iran-yekan/fonts/woff2/IRANYekanWebBold.woff2') format('woff2'),
        /* FF39+,Chrome36+, Opera24+*/
        url('../fonts/iran-yekan/fonts/ttf/IRANYekanWebBold.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebThin.eot');
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebThin.eot?#iefix') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/iran-yekan/fonts/woff/IRANYekanWebThin.woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/iran-yekan/fonts/woff2/IRANYekanWebThin.woff2') format('woff2'),
        /* FF39+,Chrome36+, Opera24+*/
        url('../fonts/iran-yekan/fonts/ttf/IRANYekanWebThin.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebLight.eot');
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebLight.eot?#iefix') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/iran-yekan/fonts/woff/IRANYekanWebLight.woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/iran-yekan/fonts/woff2/IRANYekanWebLight.woff2') format('woff2'),
        /* FF39+,Chrome36+, Opera24+*/
        url('../fonts/iran-yekan/fonts/ttf/IRANYekanWebLight.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebRegular.eot');
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebRegular.eot?#iefix') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/iran-yekan/fonts/woff/IRANYekanWebRegular.woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/iran-yekan/fonts/woff2/IRANYekanWebRegular.woff2') format('woff2'),
        /* FF39+,Chrome36+, Opera24+*/
        url('../fonts/iran-yekan/fonts/ttf/IRANYekanWebRegular.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebMedium.eot');
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebMedium.eot?#iefix') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/iran-yekan/fonts/woff/IRANYekanWebMedium.woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/iran-yekan/fonts/woff2/IRANYekanWebMedium.woff2') format('woff2'),
        /* FF39+,Chrome36+, Opera24+*/
        url('../fonts/iran-yekan/fonts/ttf/IRANYekanWebMedium.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebExtraBold.eot');
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebExtraBold.eot?#iefix') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/iran-yekan/fonts/woff/IRANYekanWebExtraBold.woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/iran-yekan/fonts/woff2/IRANYekanWebExtraBold.woff2') format('woff2'),
        /* FF39+,Chrome36+, Opera24+*/
        url('../fonts/iran-yekan/fonts/ttf/IRANYekanWebExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 850;
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebBlack.eot');
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebBlack.eot?#iefix') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/iran-yekan/fonts/woff/IRANYekanWebBlack.woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/iran-yekan/fonts/woff2/IRANYekanWebBlack.woff2') format('woff2'),
        /* FF39+,Chrome36+, Opera24+*/
        url('../fonts/iran-yekan/fonts/ttf/IRANYekanWebBlack.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebExtraBlack.eot');
    src: url('../fonts/iran-yekan/fonts/eot/IRANYekanWebExtraBlack.eot?#iefix') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/iran-yekan/fonts/woff/IRANYekanWebExtraBlack.woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/iran-yekan/fonts/woff2/IRANYekanWebExtraBlack.woff2') format('woff2'),
        /* FF39+,Chrome36+, Opera24+*/
        url('../fonts/iran-yekan/fonts/ttf/IRANYekanWebExtraBlack.ttf') format('truetype');
}

@font-face {
    font-family: "VN";
    src: url('../fonts/vn/VN.eot');
    src: url('../fonts/vn/VN.eot') format("embedded-opentype"), url('../fonts/vn/VN.ttf') format("truetype"), url('../fonts/vn/VN.woff') format("woff");
    font-weight: 400;
    font-style: normal;
}

body {
    font-family: 'iranyekan' !important;
    color: #666;
    font-size: 13px;
    color: #58595b;
    background: $gray-200;
    position: relative;
    overflow-x: hidden;
}

.max-content {
    min-width: max-content;
}

#not-found {
    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    img {
        width: 150px;
    }
}

#main {
    @media (min-width: 992px) {
        .norozMsg {
            margin-left: 10px;
        }
    }

    @media (min-width: 1000px) {
        .container {
            max-width: 950px;
        }
    }

    @media (min-width: 1100px) {
        .container {
            max-width: 1000px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1100px;
        }
    }

    @media (min-width: 1300px) {
        .container {
            max-width: 1200px;
        }
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1300px;
        }
    }

    @media (min-width: 1500px) {
        .container {
            max-width: 1400px;
        }
    }

    @media (min-width: 1600px) {
        .container {
            max-width: 1500px;
        }
    }

    @media (max-width: 992px) {
        .container {
            max-width: 100%;
        }
    }
}

#main-loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffffff87;
    z-index: 1000;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(6px);

    img {
        width: 150px;
    }
}

#box-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    z-index: 10;
}

#nothing-to-show {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        padding: 0px 10px 10px;
        border-bottom: 2px dashed $border-color;
        color: $text-muted;
    }
}

.pointer {
    cursor: pointer;
}

.back-to-top {
    width: 57px;
    height: 57px;
    position: fixed;
    right: -60px;
    bottom: 88px;
    background-color: #656464;
    border-radius: 10px;
    text-align: center;
    line-height: 57px;
    z-index: 10;
    cursor: pointer;
    border: none;
    transition: right ease 0.3s;

    i {
        color: white;
    }

    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09), 0 4px 40px rgba(0, 0, 0, 0.24) !important;
    }

    &.show {
        right: 20px;
    }
}

.bg-gray {
    background-color: $gray-300;
}

.content-box {
    border-radius: $border-radius;
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 5%);
    border: solid 1px #e4e4e5;
    background-color: $white;
}

.click-box {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(7px);
    z-index: 10;

    &.light {
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: none;
    }
}

.w-40 {
    width: 40%;
}

.w-20 {
    width: 20%;
}

.gutter-b {
    margin-bottom: 25px;
}

.main-title {
    color: #656565;
    font-size: 18px;
    font-weight: bold;
    margin: 50px 0 20px;
}

.header-box {
    padding-top: 10px;
    padding-bottom: 10px;
}

.parallelogram {
    color: #fff;
    display: flex;
    position: relative;
    height: 24px;
    font-size: 12px;
    border-top-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    margin: 0;
    padding: 0 8px;
    line-height: 24px;
    background-color: $primary;
    margin-left: 17px;

    &:last-child {
        margin-left: 0;
    }

    &.gray {
        background-color: $gray-600;

        &::before {
            border-top-color: $gray-600;
        }

        &::after {
            border-bottom-color: $gray-600;
        }
    }

    &::before {
        content: "";
        border-top: 24px solid $primary;
        border-right: 10px solid transparent;
        border-top-right-radius: 4px;
        position: absolute;
        right: -9px;
    }

    &::after {
        content: "";
        border-bottom: 24px solid $primary;
        border-left: 10px solid transparent;
        border-bottom-left-radius: 4px;
        position: absolute;
        left: -9px;
    }

    p {
        text-align: center;

        &.truncate {
            width: 103px;
        }
    }
}

.circle-empty {
    border: 2px solid $border-color;
    min-width: 11px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-left: 5px;
    display: inline-block;
}

.main {
    padding-top: 10px;
}

#box-title {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;
    color: $text-muted;
}

.main-product {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 5%);
    transition: 0.2s;
    top: 0;

    .card-body {
        padding: 0 0 15px;
    }

    .side-space {
        padding-left: 15px;
        padding-right: 15px;
    }

    .share {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 1;
        background: $gray-200;
        width: 30px;
        height: 30px;
        display: none;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        transition: 0.2s;

        i {
            font-size: 16px;
            cursor: pointer;
            display: block;
        }
    }

    &:hover {
        top: -10px;

        .share {
            display: flex;
        }
    }

    .img-holder {
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        border-radius: 6px 6px 0 0;

        img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: inherit;
        }
    }

    .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 39px;
        margin-bottom: 15px !important;
        line-height: 18px;
    }

    .customer {
        padding-top: 8px;
        margin-top: 8px !important;
        height: 25px;
        border-top: 1px solid $border-color;

        i {
            margin-left: 5px;
        }
    }

    @media (max-width: 768px) {
        .img-holder {
            height: 160px;
        }
    }

    &.horizontal {
        &:hover {
            top: 0;
        }

        .card-body {
            padding: 0;
        }

        .img-box {
            padding-left: 0;
        }

        .img-holder {
            height: 200px;
            margin: 0;
            border-radius: 0 4px 4px 0;
        }

        .side-space {
            padding-right: 0;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .customer {
            display: none;
        }

        @media (min-width: 1500px) {
            .img-holder {
                height: 225px;
            }
        }

        @media (max-width: 1400px) {
            .img-holder {
                height: 160px;
            }
        }
    }
}

.payment-callback {
    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    p {
        font-weight: bold;
        margin: 10px 0px 5px !important;
        text-align: center;
    }

    small {
        margin-bottom: 10px;
        text-align: center;
    }

    img {
        width: 200px;
    }

    button {
        margin: 20px 0px 10px;
    }

    .item {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid $text-muted;

        &:last-child {
            border: none;
        }
    }
}

.title-swiper {
    .before-title {
        padding-bottom: 10px;
    }

    .swiper-container {
        padding-top: 10px;
    }

    .main-product {
        .img-holder {
            height: 150px;

            img {
                height: 100%;
            }
        }

        .code {
            font-size: 11px;
        }

        .title {
            font-size: 12px;
        }
    }
}

// main page
.most-visited-swiper {
    padding: 10px 0px;

    .swiper-container {
        .swiper-slide {
            a {
                display: flex;
                align-items: center;
                background-color: $white;
                border: 1px solid $black;
                margin-right: 10px;
                border-radius: 30px;
                height: 45px;

                &:hover {
                    color: $primary !important;
                }

                img {
                    height: 40px;
                    width: auto;
                    border-radius: 0px 30px 30px 0px;
                }

                p {
                    font-size: 12px;
                    line-height: 14px;
                    padding: 0px 7px;
                }
            }
        }
    }
}

.filters {
    background-color: $white;
    padding: 10px;
    border-radius: $border-radius;

    .items {
        .form-check {
            margin-bottom: 0px;
        }

        .form-check:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .accordion {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        .accordion-item {
            .accordion-header {
                .accordion-button {
                    padding: 10px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;

                    span {
                        font-weight: 300;
                    }
                }
            }

            .accordion-collapse {
                .accordion-body {
                    padding: 0 !important;

                    .search-holder {
                        padding: 10px 10px 20px;

                        .form-control.form-control-solid {
                            background-color: #f0f0f1 !important;
                        }
                    }

                    .items {
                        padding: 10px;
                        max-height: 300px;
                        overflow: auto;

                        .no-data {
                            height: 180px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-bottom: 20px;
                        }
                    }

                    .price-btn {
                        width: 100%;
                        border-radius: 55px;
                        border: 0;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 700;
                    }

                    &.children {
                        display: flex;
                        flex-flow: column;

                        a {
                            margin-bottom: 10px;
                            font-weight: bold;
                            color: $text-muted !important;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    // .form-check:last-child {
                    //     margin-bottom: 0;
                    // }
                }
            }
        }

        &.space {
            .accordion-item {
                .accordion-collapse {
                    .accordion-body {
                        padding: 10px !important;
                    }
                }
            }
        }
    }

    .filter-box {
        padding: 10px;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        margin-bottom: 12px;

        .form-check {
            margin-bottom: 0;
        }
    }
}

.filter-dropdown {
    background-color: $primary;
    height: 100%;
    border-radius: 10px;
    color: $white;
    cursor: pointer;
    padding: 0px 15px;
    position: relative;

    .filters {
        position: absolute;
        left: 0;
        right: 0;
        top: 50px;
        z-index: 11;
    }
}

.sorting {
    .item {
        .holder {
            background-color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 16px;
            padding: 6px 0px;
            border-radius: 10px;

            i {
                margin-left: 4px;
            }
        }
    }

    .mobile-filter {
        position: fixed;
        left: 10px;
        right: 10px;
        bottom: -100%;
        z-index: 21;
        transition: all ease 0.3s;
        background-color: $white;
        height: calc(100vh - 100px);
        border-radius: 10px 10px 0px 0px;

        header {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: 16px;
            margin: 10px;

            i {
                position: absolute;
                right: 0;
                font-size: 20px;
            }
        }

        &.active {
            bottom: 0;
        }

        .filter-box {
            max-height: calc(100% - 50px);
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    @media (max-width: 576px) {
        .item {
            .holder {
                font-size: 11px;
            }
        }

        .mobile-filter {
            height: 100vh;
            left: 0;
            right: 0;
            border-radius: 0px;
            bottom: -100vh;
        }
    }
}

.pay-methods {
    .card {
        margin-top: 30px;
        border-radius: 10px !important;
        background-color: #e1eef7 !important;

        .card-body {
            padding: 15px 35px 20px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            .subject {
                margin-bottom: 20px !important;
            }

            .wallets-box {
                margin: 0 0 20px;
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                width: 100%;

                .form-check {
                    align-items: center;
                    display: flex;
                    margin-bottom: 15px;

                    .form-check-label {
                        font-size: 19px;
                    }
                }

                #wallet-collapse {
                    width: 100%;

                    .wallet-item {
                        align-items: center;
                        margin-bottom: 1rem;

                        .wallet-item-title {
                            font-size: 15px;
                        }

                        .form-control {
                            padding-top: 0.6rem;
                            padding-bottom: 0.6rem;
                        }

                        .refresh-icon {
                            font-size: 1rem;
                            color: $primary;
                        }
                    }
                }
            }

            .prices {
                width: 100%;
                margin-bottom: 20px;

                .item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: baseline;
                    margin-bottom: 10px;

                    .title {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .price {
                        margin: 0px 5px;
                    }

                    &.total {
                        margin-bottom: 0;

                        .price {
                            font-size: 28px;
                        }
                    }
                }
            }

            .gateways {
                .item {
                    margin-left: 10px;
                    cursor: pointer;

                    &.active {
                        img {
                            filter: grayscale(0);
                        }
                    }

                    img {
                        height: 50px;
                        filter: grayscale(0.8);
                    }

                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        .card {
            .card-body {
                button {
                    font-size: 14px !important;
                }

                .prices {
                    .item {
                        .title {
                            font-size: 13px;
                        }

                        .price {
                            margin: 0px 5px;
                        }

                        &.total {
                            .price {
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// profile page
#profile {
    .mobile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px;

        .logo {
            img {
                max-width: 170px;
                max-height: 50px;
            }
        }
    }

    display: flex;
    height: 100vh;

    .sidebar {
        background: $white;
        width: 8%;
        height: 100vh;
        font-size: 16px;
        padding: 30px 15px 30px;
        z-index: 1;
        overflow: auto;

        .close-icon {
            position: absolute;
            right: 12px;
            top: 10px;
            width: 18px;
            opacity: .5;
        }

        .logo {
            margin-bottom: 20px;

            a {
                width: 60px;
                margin: 0 auto;
                display: block;

                img {
                    max-width: 60px;
                    max-height: 30px;
                }
            }
        }

        .user-info {
            border-top: 1px solid #e4e4e5;
            border-bottom: 1px solid #e4e4e5;
            padding: 20px 0;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            img {
                border: 1px solid #e4e4e5;
                border-radius: 10px;
                display: inline-table;
                vertical-align: middle;
                width: 50px;
                height: 50px;
                margin: 0 auto;
            }

            p {
                display: none;
                font-size: 14px;
            }

            .credit {
                display: none;
            }
        }

        .menu-link {
            padding: 0px;
            margin-bottom: 0;
            list-style: none;
            text-align: center;

            li {
                margin-bottom: 10px;
                display: flex;
                justify-content: center;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: #1b1c1d;
                    padding: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    img {
                        width: 20px;
                        margin: 0 auto;
                        opacity: .5;
                    }

                    p {
                        display: none;
                    }

                    &.active,
                    &:hover {
                        background-color: #555;
                        color: $white !important;

                        img {
                            filter: brightness(0) invert(1);
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .phone {
            color: #1b1c1d;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 13px;
            border-radius: $border-radius;

            img {
                width: 25px;
            }

            a {
                display: none;
                line-height: normal;
            }
        }

        &.active {
            width: 24%;
            padding: 20px 50px 20px;

            .logo {
                a {
                    width: 200px;
                    margin: 0;

                    img {
                        max-width: 200px;
                        max-height: 56px;
                    }
                }
            }

            .user-info {
                img {
                    margin: 0;
                    margin-left: 10px;
                }

                p {
                    display: block;
                }

                .credit {
                    display: flex;
                    align-items: center;
                }
            }

            .menu-link {
                li {
                    a {
                        justify-content: flex-start;
                        width: 100%;

                        &.logout {
                            &:hover {
                                background-color: $primary;
                            }
                        }

                        img {
                            margin: 0;
                            margin-left: 5px;
                        }

                        p {
                            display: block;
                        }
                    }
                }
            }

            .phone {
                justify-content: flex-start;
                border: 2px solid $primary;

                img {
                    margin-left: 5px;
                }

                a {
                    display: block;
                    font-size: 15px;

                    &:hover {
                        color: $primary !important;
                    }
                }
            }
        }
    }

    .profile-main {
        width: 92%;
        overflow: auto;

        .page-content {
            padding: 50px 0px 20px;

            .page-title {
                font-size: 18px;
                font-weight: bold;
                color: #1b1c1d;
                margin-bottom: 30px;

                a {
                    color: #0989fb !important;
                    cursor: pointer;
                    font-size: 12px;
                }
            }
        }

        &.active {
            width: 76%;
        }
    }

    @media (max-width: 1300px) {
        .sidebar {
            &.active {
                width: 28%;
                padding: 30px;
            }
        }

        .profile-main {
            &.active {
                width: 72%;
            }
        }
    }

    @media (max-width: 992px) {
        .sidebar {
            position: fixed;
            width: 0;
            opacity: 0;
            visibility: hidden;
            top: 0;
            transition: .3s;

            .logo {
                display: none;
            }

            .user-info {
                border-top: none;
                padding: 0 15px 20px;

                p {
                    display: block;
                }
            }

            &.active {
                width: 400px;
                opacity: 1;
                visibility: visible;
                z-index: 11;
            }
        }

        .profile-main {
            width: 100% !important;

            .page-content {
                padding-top: 20px;
            }
        }
    }

    @media (max-width: 480px) {
        .sidebar {
            &.active {
                width: 100%;
            }
        }
    }

    @media (min-width: 576px) {
        .container {
            max-width: 100%;
        }
    }

    // @media (min-width: 768px) {
    //     .container {
    //         max-width: 720px;
    //     }
    // }
    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }
}

.breadcrumb {
    p {
        a {
            &::after {
                content: '/';
                margin: 0px 3px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.step-container {
    width: 60%;
    margin: auto;
    position: relative;

    .roads {
        display: flex;
        justify-content: space-evenly;

        .step-road-label {
            height: 10px;
            background-color: $gray-200;

            &.active {
                background-color: $primary;
            }
        }
    }

    .steps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: -63px;
        position: relative;

        .step-box {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            p {
                font-size: 15px;
                line-height: 50px;
                color: $text-muted;
                font-weight: 300;
            }

            span {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: $gray-100;
            }

            &.active {
                span {
                    background-color: $primary;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .steps {
            top: -48px;

            .step-box {
                p {
                    font-size: 11px;
                    line-height: 35px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        width: 85%;
        padding-bottom: 20px;

        .steps {
            top: -23px;

            .step-box {
                margin: 0px !important;
                width: 116px !important;
                position: absolute;
                height: 50px;
                justify-content: flex-end;

                p {
                    display: none;
                    color: $black;
                }

                img {
                    display: none;
                }

                &:first-child {
                    align-items: flex-start;
                }

                &:last-child {
                    align-items: flex-end;
                }
            }
        }
    }

    @media (max-width: 576px) {
        width: 100%;

        .roads {
            .step-road-label {
                background-color: $white;
                height: 5px;
            }
        }

        .steps {
            top: -21px;

            .step-box {
                span {
                    background-color: $white;
                }
            }
        }
    }
}

.address {
    table {
        tbody {
            tr {
                td {
                    i {
                        font-size: 15px;
                    }

                    .selected-address {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: 1px solid $gray-300;
                        margin-right: auto;
                        position: relative;

                        &.active {
                            &::before {
                                content: "";
                                height: 8px;
                                width: 8px;
                                background: $primary;
                                border-radius: inherit;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                border: 1px solid transparent;
                            }
                        }
                    }

                    &:first-child {
                        i {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}

.confirmMobileSection {
    display: none;
}

.baner-header {
    display: block;
}

#side-ad-container,
.side-ad-container2 {
    display: none;
}

.active-cover-body2 {
    display: none;
}

.blur .MostVisitedCategories,
.blur .head_header .head_logo_a,
.blur .head_header .basket,
.blur .head_header .header-search-btn,
.blur .navbar,
.blur .main,
.blur footer,
.blur .s-whatsapp,
.blur .s-phone-GH,
.blur .totop,
.blur #NovinikForm #scrollfix,
.blur-search .MostVisitedCategories,
.blur-search .head_header .head_logo_a,
.blur-search .head_header .basket,
.blur-search .head_header .header-search-btn,
.blur-search .navbar,
.blur-search .main,
.blur-search footer,
.blur-search .s-whatsapp,
.blur-search .s-phone-GH,
.blur-search .totop,
.blur-search #NovinikForm #scrollfix,
.blur-search .header-account {
    filter: blur(3px);
}

.blur * {
    filter: none !important;
}

#home-page-slider .owl-carousel {
    margin: 0;
    padding: 0;
    z-index: 0;
}

#home-page-slider .owl-carousel .owl-item {
    margin: 0;
    padding: 0;
}

#home-page-slider .owl-carousel .owl-dots {
    display: block !important;
    position: absolute;
    right: 50%;
    bottom: 10px;
    margin-right: -35px;
}

#home-page-slider .owl-carousel .owl-dots button {
    margin: 0;
}

#home-page-slider .owl-carousel .owl-dots button span {
    display: inline-block;
    width: 11px;
    height: 11px;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    margin: 5px 3px;
}

#home-page-slider .owl-carousel .owl-dots button.active span {
    width: 13px;
    height: 13px;
    background-color: #fff;
}


/*.navbar-toggler{*/


/*top:77px*/


/*top:126px*/


/*top:12px}*/

.carousel-indicators {
    width: 100%;
    margin-left: 0;
    left: 0;
    right: 0;
    margin-right: 0;
}

.carousel-indicators li {
    margin: 1px 3px;
}

// .main .container [class*="col-"] {
//     width: inherit;
// }
.MostVisitedCategories {
    background-color: #f0f0f1;
    padding: 10px 0;
    display: block;
}

.MostVisitedCategories .owl-carousel.owl-rtl {
    margin: 0;
    padding: 0;
}

.MostVisitedCategories .owl-item {
    padding: 0 5px;
}

.MostVisitedCategories .owl-item a {
    background-color: #fff;
    border-radius: 50px;
    display: block;
    height: 45px;
    line-height: 45px;
    padding: 0 0 0 15px;
    text-align: center;
    overflow: hidden;
    border: 1px solid #58595b;
    letter-spacing: -1px;
}

.MostVisitedCategories .owl-item a .image-item-size {
    width: 35%;
    display: inline-table;
    vertical-align: middle;
    transform: scale(1.4, 1.4);
    padding-right: 5px;
}

.MostVisitedCategories .owl-item a span {
    display: inline-table;
    vertical-align: middle;
    width: 56%;
    font-size: 12px;
    line-height: 14px;
    color: #555;
    text-align: right;
    padding-right: 7px;
}

.MostVisitedCategories .owl-item a:hover span {
    color: $primary;
}

.b-r-3 {
    border-radius: 3px;
}

.s-whatsapp,
.s-phone-GH {
    display: none;
}

.home-top-box .groupingTitle {
    margin-bottom: 15px;
}

.home-top-box .groupingTitle .groupingTitle-head {
    display: inline-table;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 18px;
    font-weight: 700;
}

.home-top-box .groupingTitle i {
    display: inline-table;
    vertical-align: middle;
    transform: rotate(180deg);
}

.home-top-box .category-title {
    font-weight: 700;
    margin-bottom: 20px;
}

.home-top-box .category-title a:hover {
    color: $primary;
}

.home-top-box .category-site .article {
    padding: 20px 15px;
    margin-bottom: 23px;
}

.home-top-box .category-site .article:nth-child(2) {
    background: #fbf3f0;
}

.home-top-box .category-site .article:nth-child(3) {
    background: #f5f9f4;
}

.home-top-box .category-site .article:nth-child(4) {
    background: #f6f8f9;
    margin-bottom: 0;
}

.home-top-box .category-site .article ul li {
    margin-bottom: 11.5px;
}

.home-top-box .category-site .article ul li:last-child {
    margin-bottom: 0;
}

.home-top-box .category-site .article ul li:hover a {
    color: $primary;
}

.home-top-box .category-site .article ul li a:before {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 5px;
    display: inline-table;
    opacity: 0;
}

.home-top-box .category-site .article ul li:hover a:before {
    opacity: 1;
}

.carousel.slide img {
    display: block;
    width: 100% !important;
    height: auto;
    max-width: 100%;
}

.home-top-box {
    padding-top: 30px;
}

.home-bottom-box {
    padding-bottom: 30px;
}

// .home-top-box .container {
//     padding: 0;
//     position: relative;
// }
.home-top-box-image ul li {
    margin-bottom: 17px;
}

.home-top-box-image ul li:last-child {
    margin-bottom: 0;
}

.home-top-box-image ul li a,
.home-top-box-image ul li a img {
    display: block;
    width: 100%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background: url(https://static.vitrinnet.com/img/home-new/arrow-slide.svg);
    width: 34px;
    height: 41px;
    background-size: 100%;
}

.carousel-control-prev-icon {
    transform: rotate(180deg);
}

.owlcarousel {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.owl-carousel .owl-item {
    text-align: center;
    padding: 0 15px;
}

.hidden-mobile img {
    width: 100%;
}

.owl-carousel {
    text-align: center;
}

.owl-carousel .item .imageBox img {
    max-width: 130px;
    margin: 0 auto;
}

.hidden-desktop {
    display: none;
}

.hidden-mobile {
    height: auto !important;
    padding: 0 !important;
}

.baner-device {
    display: none;
}

.equipment-video {
    display: none;
    margin-top: -100px;
    margin-bottom: 30px;
}

.equipment-video .owl-stage {
    margin: 0 auto;
}

.equipment-video .box-title {
    color: #fff;
    margin-bottom: 15px;
    margin-right: 5px;
}

.equipment-video .item {
    padding: 0;
}

.equipment-video .item a {
    background: #fff;
    padding: 15px;
    display: block;
    width: 100%;
    border-radius: 2.5px;
}

.equipment-video .item .equipment-video-title {
    margin-top: 10px;
}

.equipment-video .container {
    padding-left: 0;
}

.bigBaner {
    margin-bottom: 25px;
}

.bigBaner a {
    display: block;
    width: 100%;
}

.bigBaner img {
    width: 100%;
}

.bigBaner .image-small {
    display: none !important;
}

.entekhab-taminkonandeh-ostan {
    margin-bottom: 20px;
}

.entekhab-taminkonandeh-ostan .owl-carousel {
    margin: 0;
    padding: 0 20px;
}

.entekhab-taminkonandeh-ostan .container {
    position: relative;
}

.entekhab-taminkonandeh-ostan .container:before {
    background: url(https://static.vitrinnet.com/img/home-new/Iran-map.png) no-repeat;
    content: "";
    width: 268px;
    height: 234px;
    top: -4px;
    position: absolute;
    right: 2%;
    z-index: -1;
    background-size: 53%;
}

.entekhab-taminkonandeh-ostan .ostan-box {
    background: #fff;
    border-radius: 2.5px;
    padding: 18px 0;
}

.entekhab-taminkonandeh-ostan .entekhab-taminkonandeh-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.owl-carousel .owl-dots {
    display: none !important;
}

.entekhab-taminkonandeh-ostan .item a {
    line-height: 30px;
}

.entekhab-taminkonandeh-ostan .item a:hover {
    color: $primary;
}

.bg-color2 {
    background-color: #e5e5e5;
    padding-top: 50px;
    padding-bottom: 25px;
    margin-bottom: 30px;
}

.home-section3-mobile .owl-stage {
    width: max-content !important;
}

.home-section3-mobile .owl-item {
    width: 225px !important;
}

.home-section3-mobile .owl-carousel {
    padding: 0;
}

.home-section3-mobile {
    padding-left: 0;
    padding-right: 10px;
}

@media (max-width: 1599px) {
    .home-top-box-image ul li {
        margin-bottom: 21px;
    }

    .home-top-box .category-site .article ul li {
        margin-bottom: 10.5px;
    }

    .home-top-box .category-site .article {
        margin-bottom: 13px;
    }
}

@media (max-width: 1499px) {
    .home-top-box-image ul li {
        margin-bottom: 26px;
    }

    .home-top-box .category-site .article ul li {
        margin-bottom: 10px;
    }

    .home-top-box .category-site .article {
        margin-bottom: 16px;
    }

    .home-top-box .category-title {
        margin-bottom: 15px;
    }

    .home-top-box .category-site .article {
        padding: 17px 15px;
    }
}

@media (max-width: 1399px) {
    .home-top-box .category-site .article {
        margin-bottom: 5px;
    }

    .home-top-box .category-title {
        margin-bottom: 15px;
    }

    .home-top-box-image ul li {
        margin-bottom: 34px;
    }

    .home-top-box .category-site .article ul li {
        margin-bottom: 8.8px;
    }

    .hidden-mobile {
        flex: 0 0 18%;
        max-width: 18%;
    }
}

@media (max-width: 1299px) {
    .home-top-box .category-site .article ul li {
        margin-bottom: 7.6px;
    }

    .home-top-box .category-site .article {
        padding: 13px;
    }

    /*.bg-color2{padding-bottom:5px}*/
}

@media (max-width: 1199px) {
    .home-top-box .category-site .article {
        padding: 10px;
        margin-bottom: 4px;
    }

    .owl-carousel .item .imageBox img {
        width: 100%;
    }

    .home-top-box .groupingTitle {
        margin-bottom: 10px;
    }

    .home-top-box .groupingTitle .groupingTitle-head {
        font-size: 16px;
    }

    .home-top-box .category-title {
        margin-bottom: 10px;
    }
}

@media (max-width: 1099px) {

    // .home-top-box .container>.home-section2 {
    //     padding: 0;
    // }
    .home-top-box .category-site .article ul li a:before {
        display: none;
    }

    .home-top-box .category-title {
        margin-bottom: 8px;
    }

    .home-top-box .category-site .article ul li {
        margin-bottom: 9.3px;
    }

    .home-top-box-image ul li {
        margin-bottom: 46px;
    }

    .baner-device {
        display: block;
        margin-bottom: 20px;
        position: relative;
    }

    .baner-device img {
        width: 100%;
    }

    .hidden-mobile {
        display: none;
    }
}

@media (max-width: 999px) {
    .home-top-box .category-site .article {
        margin-bottom: 13px;
    }

    .home-top-box .category-site .article ul li {
        font-size: 13px;
    }
}

@media (max-width: 993px) {
    .bigBaner .container {
        padding: 0 18px !important;
    }

    .entekhab-taminkonandeh-ostan .container {
        padding: 0 20px !important;
    }

    // .home-top-box .container {
    //     padding: 0 20px;
    // }
}

@media (max-width: 960px) {
    .home-top-box .category-site .article {
        margin-bottom: 0;
    }
}

@media (max-width: 930px) {
    .home-top-box .category-site .article ul li {
        font-size: 13px;
    }
}

@media (max-width: 901px) {
    .home-top-box .category-site .article {
        margin-bottom: 7px;
    }

    .home-top-box .category-site .article ul li {
        font-size: 12px;
    }

    .home-top-box .category-title {
        font-size: 13px;
    }

    .home-top-box .category-site .article ul li {
        margin-bottom: 8px;
    }
}

@media (max-width: 850px) {
    .home-top-box-image {
        display: initial;
    }

    /*.home-top-box-image{display:none}*/
    // .home-top-box .container>.home-section1 {
    //     padding-left: 0;
    // }
    // .home-top-box .container>.home-section2 {
    //     padding: 0;
    // }
    .home-top-box .category-site .article {
        margin-bottom: 0;
        padding: 14px;
    }

    .home-top-box .category-site .article ul li {
        margin-bottom: 10px;
    }

    .home-top-box .category-title {
        margin-bottom: 15px;
    }

    .home-top-box .col-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .home-top-box .col-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

@media (max-width: 812px) {
    .home-top-box .category-site .article ul li {
        margin-bottom: 8.9px;
    }
}

@media (max-width: 790px) {
    .home-top-box .category-site .article ul li {
        margin-bottom: 8px;
    }
}

@media (max-width: 767px) {
    .home-section3-mobile {
        padding-right: 0;
        margin-right: -15px;
    }

    .entekhab-taminkonandeh-ostan .owl-item {
        padding: 0;
    }

    .home-top-box .category-site .article {
        padding: 12px;
    }

    .hidden-desktop {
        display: none;
    }

    .baner-device {
        display: none;
    }

    .bigBaner .container,
    .bigBaner .container>div {
        padding: 0 !important;
    }

    .bigBaner .col-md-6 {
        margin-bottom: 20px;
    }

    /*.bg-color2{padding:20px 0 0}*/
    .carousel-indicators li {
        margin: 1px 5px;
    }
}

@media (max-width: 750px) {
    .home-top-box .groupingtitle {
        display: none;
    }
}

@media (max-width: 720px) {
    .home-top-box .category-site .article ul li {
        margin-bottom: 10px;
    }

    .home-top-box .category-title {
        margin-bottom: 11px;
    }

    .home-top-box .category-site .article {
        padding: 12px;
    }
}

@media (max-width: 690px) {
    .home-top-box .category-site .article {
        padding: 12px;
    }

    .home-top-box .category-site .article ul li {
        margin-bottom: 8px;
    }
}

@media (max-width: 680px) {
    .home-top-box .category-site .article ul li {
        margin-bottom: 8px;
    }
}

@media (max-width: 650px) {
    .home-top-box .category-site .article {
        padding: 10px;
    }
}

@media (max-width: 623px) {

    /*.navbar-toggler {*/
    /*top: 74px;*/
    /*top: 126px;
}*/
    .home-top-box .container>.home-section1 {
        display: none;
    }

    .home-top-box .container>.home-section2 {
        max-width: 100% !important;
        padding: 0;
    }

    .b-r-3 {
        border-radius: 0;
    }
}

@media (max-width: 575px) {
    .home-section3-mobile {
        padding-right: 10px;
        margin-right: 0;
    }

    .home-section3-mobile .owl-carousel {
        margin-top: 30px;
    }

    .home-section2 .carousel-inner {
        border-radius: 0;
    }

    // .home-top-box .container {
    //     padding: 0;
    // }
    #home-page-slider .owl-carousel .owl-dots {
        display: none !important;
    }

    .home-top-box {
        padding-top: 0;
    }

    .home-bottom-box {
        padding-bottom: 0;
    }
}

@media (max-width: 370px) {
    .home-section3-mobile .owl-item {
        width: 214px !important;
    }
}

@media (max-width: 480px) {
    .owl-carousel.owl-rtl .owl-item {
        padding: 0 5px;
    }

    .equipment-video {
        display: block;
    }

    .equipment-video .imageBox img {
        width: 75px;
    }

    .equipment-video .container {
        padding-right: 10px;
    }

    .bigBaner .image-large {
        display: none !important;
    }

    .bigBaner .image-small {
        display: block !important;
    }

    .entekhab-taminkonandeh-ostan {
        display: none;
    }

    .bigBaner {
        margin-bottom: 20px;
    }

    .bg-color2 {
        padding: 30px 0;
    }

    #home-page-slider .owl-carousel .owl-dots {
        bottom: 0;
    }
}

.clear-mobile {
    clear: both;
    display: none;
}

.black-cover-body {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.active-cover-body,
.active-cover-body2,
.active-cover-body3,
.active-cover-body4,
.active-cover-body-serach {
    opacity: 1;
    visibility: visible;
}

.active-cover-body3 {
    z-index: 21;
}

.active-cover-body4 {
    z-index: 20;
}

.active-cover-body2 {
    opacity: 1;
}

@-moz-keyframes ldld-default {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes ldld-default {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes ldld-default {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes ldld-default {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-button {
    position: relative;
    color: transparent !important;
}

.loading-button:before {
    content: " ";
    color: #fff !important;
    display: block;
    background: 0;
    animation: ldld-default 0.5s ease-in-out infinite;
    border-radius: 50%;
    margin: 0;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-color: currentColor transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    z-index: 3;
}

#swal2-title {
    font-size: 20px;
}

#swal2-content {
    font-size: 15px;
}

input.v3,
select.v3 {
    height: 34px;
    border-radius: 8px;
    border: solid 1px #c6c6c6;
    width: 100%;
    margin-bottom: 6px;
    padding: 0 10px;
    position: relative;
}

.inputlabel span.text {
    font-size: 11px;
    color: #e1384d;
    padding-right: 18px;
    margin-right: 5px;
    display: none;
}

.inputlabel span.text.err {
    display: inline-block;
}

input.v3.err,
select.v3.err,
textarea.v3.err {
    border-color: #e1384d;
    border-color: #e1384d;
    border-color: #e1384d;
}

.inputlabel span.text {
    position: relative;
}

.inputlabel span.text:before {
    font-family: VN;
    content: "";
    color: #e1384d;
    position: absolute;
    right: 0;
    top: -3px;
    font-size: 14px;
}

textarea.v3 {
    border-radius: 8px;
    border: solid 1px #c6c6c6;
    width: 100%;
    margin-bottom: 6px;
    padding: 10px;
    position: relative;
    resize: none;
}

input.v3:focus,
textarea.v3:focus,
select.v3:focus {
    border-color: #707070;
    outline: none;
}

.btn-v3 {
    margin: 6px 0;
    height: 42px;
    border-radius: 21px;
    background-color: #e1384d;
    width: 100%;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    font-size: 18px;
    border: 0;
    -moz-box-shadow: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.ui-pnotify {
    direction: rtl;
    top: 25px;
    right: 25px;
    position: absolute;
    height: auto;
    z-index: 9999;
    text-align: right;
    opacity: 0.9 !important;
}

html>body .ui-pnotify {
    position: fixed;
}

.ui-pnotify .ui-pnotify-shadow {
    -webkit-box-shadow: 0 2px 10px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0 2px 10px rgba(50, 50, 50, 0.5);
    box-shadow: 0 2px 10px rgba(50, 50, 50, 0.5);
}

.ui-pnotify-container {
    background-position: 0 0;
    padding: 0.8em;
    height: 100%;
    margin: 0;
}

.ui-pnotify-sharp {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.ui-pnotify-closer,
.ui-pnotify-sticker {
    float: left;
    margin-right: 0.2em;
}

.ui-pnotify-title {
    display: block;
    margin-bottom: 0.4em;
    font-weight: 700;
}

.ui-pnotify-text {
    display: block;
}

.ui-pnotify-icon,
.ui-pnotify-icon span {
    display: block;
    float: right;
    margin-left: 0.2em;
}

.ui-pnotify-history-container {
    position: absolute;
    top: 0;
    right: 18px;
    width: 70px;
    border-top: none;
    padding: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-top-right-radius: 0;
    border-top-right-radius: 0;
    z-index: 10000;
}

.ui-pnotify-history-container .ui-pnotify-history-header {
    padding: 2px;
}

.ui-pnotify-history-container button {
    cursor: pointer;
    display: block;
    width: 100%;
}

.ui-pnotify-history-container .ui-pnotify-history-pulldown {
    display: block;
    margin: 0 auto;
}

.userpanel .ui-pnotify {
    margin-right: -150px;
    right: 50%;
}

.ui-pnotify .alert-success {
    background-color: #42a19d;
    color: #fff;
    border-color: #5aa7a4;
}

.navbar .sub-menu li.submenu-right {
    padding-top: 0;
    padding-bottom: 0;
}

@media (max-width: 1200px) {
    .navbar .sub-menu2 {
        padding-bottom: 0;
    }

    .navbar .sub-menu .imageBox img {
        max-width: 350px;
    }
}

@media (max-width: 1020px) {
    .navbar .sub-menu2 .navbar-nav>li {
        min-width: 193px;
    }

    .navbar ul.navbar-nav>li {
        padding: 20px 15px;
    }
}

@media (max-width: 992px) {
    .p-0-mobile {
        padding: 0 !important;
    }
}

@media (max-width: 768px) {
    #menuHtml .col-sm-3 {
        float: right;
    }

    .clear-mobile {
        display: block;
    }
}

@media (max-width: 620px) {
    .norozMsg p {
        width: 87%;
    }

    .navbar-toggler {
        top: 8px;
    }
}

@media (max-width: 554px) {
    .clear-mobile {
        display: none;
    }
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    // filter: alpha(opacity=80);
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mfp-container:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: zoom-in;
}

.mfp-auto-coursor .mfp-content {
    cursor: auto;
}

.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #ccc;
}

.mfp-preloader a:hover {
    color: #fff;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    padding: 0;
    z-index: 1046;
}

button::-moz-focus-inner {
    padding: 0;
    margin: 0;
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    left: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=65);
    // filter: alpha(opacity=65);
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
    -ms-filter: none;
    filter: none;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=65);
    // filter: alpha(opacity=65);
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
    -ms-filter: none;
    filter: none;
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: solid transparent;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-a {
    border-top-width: 21px;
    border-bottom-width: 21px;
}

.mfp-arrow-left {
    left: 0;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
    border-right: 17px solid #fff;
    margin-left: 31px;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
    margin-left: 25px;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
    border-left: 17px solid #fff;
    margin-left: 39px;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    background: #000;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0;
    margin: 0 auto;
}

.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    background: #444;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-break: break-all;
    padding-right: 36px;
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

.mfp-fade.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    -ms-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    // filter: alpha(opacity=80);
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    -ms-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
    -ms-filter: none;
    filter: none;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    // filter: alpha(opacity=80);
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-newspaper .mfp-with-anim {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0) rotate(500deg);
    -moz-transform: scale(0) rotate(500deg);
    -o-transform: scale(0) rotate(500deg);
    -ms-transform: scale(0) rotate(500deg);
    transform: scale(0) rotate(500deg);
}

.mfp-newspaper.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
}

.mfp-newspaper.mfp-ready .mfp-with-anim {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: scale(1) rotate(0deg);
    -moz-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
}

.mfp-newspaper.mfp-ready.mfp-bg {
    opacity: 0.8;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    // filter: alpha(opacity=80);
}

.mfp-newspaper.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(0) rotate(500deg);
    -moz-transform: scale(0) rotate(500deg);
    -o-transform: scale(0) rotate(500deg);
    -ms-transform: scale(0) rotate(500deg);
    transform: scale(0) rotate(500deg);
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-newspaper.mfp-removing.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-move-horizontal .mfp-with-anim {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -o-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
}

.mfp-move-horizontal.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}

.mfp-move-horizontal.mfp-ready .mfp-with-anim {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.mfp-move-horizontal.mfp-ready.mfp-bg {
    opacity: 0.8;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    // filter: alpha(opacity=80);
}

.mfp-move-horizontal.mfp-removing .mfp-with-anim {
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -o-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-move-horizontal.mfp-removing.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-move-from-top .mfp-content {
    vertical-align: top;
}

.mfp-move-from-top .mfp-with-anim {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
}

.mfp-move-from-top.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
}

.mfp-move-from-top.mfp-ready .mfp-with-anim {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.mfp-move-from-top.mfp-ready.mfp-bg {
    opacity: 0.8;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    // filter: alpha(opacity=80);
}

.mfp-move-from-top.mfp-removing .mfp-with-anim {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -o-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-move-from-top.mfp-removing.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-3d-unfold .mfp-content {
    -webkit-perspective: 2000px;
    -moz-perspective: 2000px;
    -ms-perspective: 2000px;
    perspective: 2000px;
}

.mfp-3d-unfold .mfp-with-anim {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(-60deg);
    -moz-transform: rotateY(-60deg);
    -o-transform: rotateY(-60deg);
    -ms-transform: rotateY(-60deg);
    transform: rotateY(-60deg);
}

.mfp-3d-unfold.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
}

.mfp-3d-unfold.mfp-ready .mfp-with-anim {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.mfp-3d-unfold.mfp-ready.mfp-bg {
    opacity: 0.8;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    // filter: alpha(opacity=80);
}

.mfp-3d-unfold.mfp-removing .mfp-with-anim {
    -webkit-transform: rotateY(60deg);
    -moz-transform: rotateY(60deg);
    -o-transform: rotateY(60deg);
    -ms-transform: rotateY(60deg);
    transform: rotateY(60deg);
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-3d-unfold.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-zoom-out .mfp-with-anim {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
}

.mfp-zoom-out.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    -ms-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
    opacity: 0.8;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    // filter: alpha(opacity=80);
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-zoom-out.mfp-removing.mfp-bg {
    opacity: 0;
    // -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    // filter: alpha(opacity=0);
}

.mfp-dialog {
    background: #fff;
    padding: 20px 30px;
    text-align: left;
    max-width: 400px;
    margin: 40px auto;
    position: relative;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.mfp-dialog>h5 {
    margin-bottom: 0;
}

.mfp-dialog .dialog-icon {
    position: absolute;
    right: -60px;
    top: 5px;
    width: 60px;
    height: 60px;
    display: block;
    line-height: 60px;
    text-align: center;
    color: #fff;
    background: #2a8fbd;
    font-size: 27px;
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

.mfp-dialog .dialog-form,
.mfp-dialog .social-media-login {
    padding-top: 15px;
    margin-top: 15px;
    margin-bottom: 0;
    border-top: 1px solid #d9d9d9;
}

.mfp-dialog .dialog-form>.btn,
.mfp-dialog .social-media-login>.btn {
    margin-top: 10px;
}

.mfp-dialog .dialog-alt-links {
    list-style: none;
    margin: 0;
    position: absolute;
    right: 30px;
    bottom: 20px;
    font-size: 13px;
}

.mfp-dialog .dialog-alt-links li {
    line-height: 1em;
    float: left;
    margin-left: 15px;
}

.mfp-dialog-big {
    max-width: 800px;
}

.cursor-pointer {
    cursor: pointer;
}

.delivery-alert {
    padding: 20px 0;
    border-top: 2px solid $border-color;

    img {
        margin-left: 5px;
        width: 30px;
    }

    .title {
        font-size: 15px;
        margin-bottom: 10px !important;
    }

    .methods {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.cta-main-wrapper {
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    position: relative;
}

.cta-wrapper {
    max-height: 490px;
    background-color: $primary;
    background-image: url(../../assets/img/cta-bg.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 24px;
    flex: 1;
    align-items: center;
    padding: 64px 80px;
    display: flex;

    @media (max-width: 991px) {
        max-height: none;
        flex-direction: column;
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (max-width: 768px) {
        border-radius: 16px;
        padding: 48px 24px;
    }
}

.image-wrapper {
    width: 45%;
    flex: 0 auto;
    align-self: flex-end;
    margin-bottom: -64px;
    position: relative;

    @media (max-width: 991px) {
        width: 64%;
        order: 1;
        margin-top: 64px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 768px) {
        width: 80%;
        margin-bottom: -48px;
    }

    @media (max-width: 576px) {
        width: 100%;
        margin-top: 80px;
    }
}

.cta-image {
    width: 100%;
    max-width: 487px;
    min-width: 400px;
    max-height: 555px;
    flex: 1;
    display: block;
    object-fit: contain;

    @media (max-width: 991px) {
        min-width: auto;
    }
}

.white-pre {
    white-space: pre;
}

.cta-content {
    color: #fff;
    max-width: 460px;
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    right: 32px;

    @media (max-width: 991px) {
        max-width: 500px;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        position: static;
    }
}

.apps-download {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    img {
        height: 61px;
    }

    @media (max-width: 991px) {
        justify-content: space-between;
    }

    @media (max-width: 576px) {
        justify-content: center;
    }
}


@media (max-width: 576px) {
    .google-captcha {
        transform: scale(0.7);
        transform-origin: 0 0;

        >div {
            >div {
                width: 0 !important
            }
        }
    }
}
#main-category {
    header {
        padding: 0px;
        border: none;
        position: relative;
        display: flex;
        align-items: center;
        .title {
            position: absolute;
            right: 7%;
            font-size: 50px;
            color: $white;
        }
    }
    .main-card {
        >.card-body {
            padding: 24px 12px;
            .item {
                border: none;
                height: 100%;
                .card-header {
                    height: 180px;
                    padding: 0px;
                    border: none;
                    img {
                        height: 100%;
                        object-fit: cover;
                        border-radius: 5px;
                        width: 100%;
                    }
                }
                .card-body {
                    padding: 30px 0 0;
                    .title {
                        font-size: 15px;
                        font-weight: bold;
                        color: $black;
                        margin-bottom: 10px !important;
                        line-height: 24px;
                    }
                    a {
                        color: #219653 !important;
                    }
                    .main-link {
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 21px;
                        display: flex;
                        align-items: center;
                    }
                    .description {
                        font-size: 10px;
                        color: $text-muted;
                        line-height: 19px;
                        margin: 10px 0 !important;
                    }
                    .childrens {
                        a {
                            font-size: 10px;
                            line-height: 21px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        header {
            margin-top: -10px;
            margin-left: -12px;
            margin-right: -12px;
            .title {
                font-size: 35px;
            }
        }
        .before-title {
            padding: 10px 0 0;
        }
        .main-card {
            background-color: transparent;
            >.card-body {
                padding: 20px 0px 0px;
                .item {
                    .card-header {
                        img {
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                        }
                    }
                    .card-body {
                        padding: 20px;
                        .childrens {
                            a {
                                display: inline-block;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 576px) {
        header {
            display: none;
        }
    }
}
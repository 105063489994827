.panel-item-box {
    padding: 15px !important;

    &.no-padding {
        padding: 0 !important;
    }

    .accordion-item {
        .accordion-body {
            .suborder-item:not(:last-of-type) {
                margin-bottom: 8rem;
            }
        }
    }

    .code {
        border-bottom: 1px solid #2185d0;
        font-size: 13px;
        width: 100%;
        font-weight: 500;
        margin-bottom: 3px !important;
    }

    table {
        width: 100%;

        tbody {
            tr {
                border-top: 1px solid #e4e4e5;

                &:first-child {
                    border: none;
                }

                td {
                    padding: 10px 15px;

                    .buy-qoute {
                        opacity: 0.2;

                        &.active {
                            opacity: 1;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

#profile {
    .card-links {
        margin-bottom: 30px;
    }

    .other-link {
        text-align: center;

        .dropdown-toggle {
            background: transparent;
            border: none;
            padding: 0px !important;

            i {
                font-size: 16px !important;
            }

            &::after,
            &::before {
                display: none;
            }
        }

        .dropdown-menu {
            .dropdown-item {
                text-align: right;
                font-size: 12px;
            }
        }
    }

    .product-code {
        border-bottom: 1px solid #2185d0;
        font-size: 13px;
        width: 100%;
        font-weight: 500;
        margin-bottom: 3px !important;
    }

    .user-info {
        margin-bottom: 15px;

        .card-body {
            padding: 15px;

            a {
                &:hover {
                    font-weight: bold;
                }
            }
        }
    }

    .panel-product {
        .card-body {
            .img-holder {
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: -10px -10px 10px;
                border-radius: 4px 4px 0 0;

                img {
                    display: block;
                    margin: 0 auto;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    border-radius: inherit;
                }
            }

            .product-title {
                margin-bottom: 10px;

                .product-code {
                    color: #2185d0;
                }

                .product-name {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    height: 39px;
                }
            }

            .price {
                display: flex;
                justify-content: flex-end;
                font-size: 13px;
                gap: .25rem;
            }

            .other-link {
                position: absolute;
                left: 10px;

                .dropdown-toggle {
                    padding: 4px 9px !important;
                    background: $gray-300;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

#update-user {
    .submit-button {
        margin-top: 30px;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        width: 122px;
    }

    @media (max-width: 576px) {
        .submit-button {
            width: 100%;
        }
    }
}

#orders {
    .accordion {
        .accordion-item {
            .accordion-collapse {
                .accordion-body {
                    &:not(.initial-padding) {
                        padding-top: 110px;
                    }

                    .step-container {
                        height: 60px;
                        width: 80%;

                        .steps {
                            top: -113px;

                            .step-box {
                                img {
                                    max-width: 100px;
                                }
                            }
                        }
                    }

                    .delivery-alert {
                        border-top: 0;
                        border-bottom: 2px solid $border-color;
                    }

                    @media (max-width: 768px) {

                        // padding-top: 75px;
                        .step-container {
                            .steps {
                                top: -73px;

                                .step-box {
                                    height: 150px;

                                    &.last-active {
                                        top: -40px;
                                    }
                                }
                            }
                        }
                    }

                    @media (max-width: 576px) {
                        .step-container {
                            width: 100%;

                            .roads {
                                .step-road-label {
                                    background-color: $gray-200;

                                    &.active {
                                        background-color: $primary;
                                    }
                                }
                            }

                            .steps {
                                top: -70px;

                                .step-box {
                                    span {
                                        background-color: $primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#question-modal {
    .question {
        margin-bottom: 15px;
        display: flex;

        i {
            color: #00a7ff;
            font-size: 18px;
            padding-left: 10px;
        }
    }

    .answer {
        margin-right: 35px;
    }
}

#return-modal {
    .dropzone {
        margin-bottom: 20px;
    }

    .boxed-icon {
        background-color: $gray-200;
        padding: 4px 6.5px;
        border-radius: 50%;
        cursor: pointer;

        &.small {
            padding: 4px 5.5px;
        }
    }

    .cart-item-image {
        img {
            height: 50px;
            border-radius: 10px;
        }
    }

    .cart-item-count-box {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        border: 1px solid $border-color;
        border-radius: 10px;
        padding: 8px 10px;

        .counter {
            display: flex;
            align-items: center;

            span {
                width: 30px;
                font-size: 12px;
            }
        }

        p {
            width: 100%;
            font-size: 12px;
            text-align: center;
        }
    }

    .cart-item-title {
        padding-right: 5px;
        text-align: justify;
        line-height: 21px;
        font-size: 12px;

        small {
            font-size: 10px !important;

            span {
                margin-left: 2px;

                &::after {
                    content: ",";
                }

                &:last-child {
                    margin-left: 0;

                    &::after {
                        content: '';
                    }
                }
            }
        }
    }

    .cart-item-price {
        font-size: 20px;
        font-weight: bold;
        margin-left: 4px;
        color: $success;
    }

    .product-table {
        width: 100%;

        thead {
            display: none;
        }

        tbody {
            tr {
                border-top: 1px solid $border-color;

                td {
                    vertical-align: middle;
                    color: #444;
                    font-size: 15px;
                    text-align: center;
                    padding: 10px 0;

                    &:nth-child(3) {
                        min-width: 170px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .cart-item-image {
            img {
                height: 40px;
            }
        }

        .cart-item-count-box {
            width: 130px;
            padding: 9px 7px;
            flex-flow: column;

            p {
                margin-top: 5px !important;
            }
        }
    }

    @media (max-width: 576px) {
        .cart-item-title {
            padding-left: 30px;
        }

        .cart-item-price {
            font-weight: normal;
            font-size: 16px;
        }

        .product-table {
            width: 100%;

            tbody {
                tr {
                    position: relative;
                    border-top: none;
                    border-bottom: 1px solid $border-color;

                    &:first-child {
                        border: none;
                    }

                    td {
                        &:nth-child(1) {
                            position: absolute;
                            left: 0;
                            top: 0;
                        }

                        &:nth-child(2) {
                            width: 100%;
                            padding: 0;
                            display: block;
                        }

                        &:nth-child(3) {
                            min-width: unset;
                            display: block;
                            float: right;
                        }

                        &:nth-child(4) {
                            display: block;
                            float: left;
                        }
                    }
                }
            }
        }
    }
}

.wallet-card {
    background-image: url(../../../../../public/assets/images/wave.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: $primary;

    .title {
        margin-bottom: 20px !important;
    }

    .wallet-item-title {
        font-size: 15px;
    }
}

#wallets {
    .tab-content {
        padding-top: 9px;
        padding-bottom: 24px;
    }

    .table> :not(caption)>*>* {
        padding: 0;
    }

    .wallet-table {

        td,
        th {
            padding: 10px;
            font-size: 1rem;
            vertical-align: top;

            &.description {
                min-width: 200px;
            }
        }
    }
}

#tickets {
    table {

        td,
        th {
            padding: 10px;
            font-size: 1rem;
            vertical-align: top;
        }
    }
}

#create-ticket {
    .submit-button {
        margin-top: 30px;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        width: 122px;
    }

    @media (max-width: 576px) {
        .submit-button {
            width: 100%;
        }
    }
}

#reply-ticket {

    .symbol {
        display: inline-block;
        flex-shrink: 0;
        position: relative;
        border-radius: .42rem;

        .symbol-label {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            border-radius: .42rem;
        }
    }

    .submit-button {
        margin-top: 30px;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        width: 122px;
    }

    @media (max-width: 576px) {
        .submit-button {
            width: 100%;
        }
    }
}

.tab-pane {
    position: relative;
}
#cart-page {
    .empty-cart {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 30px 0px;
    }

    .cart-header {
        padding: 23px 0px;
        background-color: $white;

        a {
            height: 40px;
            display: flex;
        }
    }

    .why-trust {
        .accordion {
            box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
            margin-top: 16px;
            border-radius: 8px;

            .accordion-item {
                border-radius: inherit;

                .accordion-header {
                    border-radius: inherit;

                    .accordion-button {
                        border-radius: inherit;
                        font-size: 12px;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        font-weight: 600;

                        img {
                            width: 27px;
                            margin-left: 8px;
                        }
                    }
                }

                .accordion-body {
                    .item {
                        margin-bottom: 20px;
                        font-size: 12px;

                        .title {
                            color: $primary;
                            margin-bottom: 10px !important;
                            font-weight: bold;
                            display: flex;
                            align-items: center;

                            i {
                                color: $primary;
                                font-size: 9px;
                                margin-left: 11px;
                            }
                        }

                        .subject {
                            text-align: justify;
                            line-height: 24px;
                            margin-bottom: 10px !important;
                        }
                    }
                }
            }
        }
    }

    main {
        padding-top: 20px;

        .cart-holder {
            border-radius: 10px !important;

            >.card-body {
                padding: 50px 30px 20px;

                .cart-container {
                    width: 85%;
                    margin: auto;
                    border-radius: 10px !important;

                    .card-body {
                        padding: 10px 30px;

                        .cart-products {
                            .boxed-icon {
                                background-color: $gray-200;
                                padding: 4px 6.5px;
                                border-radius: 50%;
                                cursor: pointer;

                                &.small {
                                    padding: 4px 5.5px;
                                }
                            }

                            .cart-item-image {
                                img {
                                    height: 50px;
                                    width: 50px;
                                    object-fit: cover;
                                    border-radius: 10px;
                                }
                            }

                            .cart-item-count-box {
                                width: 200px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: auto;
                                border: 1px solid $border-color;
                                border-radius: 10px;
                                padding: 8px 10px;

                                .counter {
                                    display: flex;
                                    align-items: center;

                                    span {
                                        width: 30px;
                                        font-size: 12px;
                                    }
                                }

                                p {
                                    width: 100%;
                                    font-size: 12px;
                                    text-align: center;
                                }
                            }

                            .cart-item-title {
                                padding-right: 5px;
                                text-align: justify;
                                line-height: 21px;
                                font-size: 12px;

                                small {
                                    font-size: 10px !important;

                                    span {
                                        margin-left: 2px;

                                        &::after {
                                            content: ",";
                                        }

                                        &:last-child {
                                            margin-left: 0;

                                            &::after {
                                                content: '';
                                            }
                                        }
                                    }
                                }
                            }

                            .cart-item-price {
                                font-size: 20px;
                                font-weight: bold;
                                margin-left: 4px;
                                color: $success;
                            }

                            table {
                                width: 100%;

                                thead {
                                    tr {
                                        th {
                                            font-size: 14px;
                                            font-weight: bold;
                                            color: #58595b !important;
                                            padding-bottom: 10px;
                                            text-align: center;
                                        }
                                    }
                                }

                                tbody {
                                    tr {
                                        border-top: 1px solid $border-color;

                                        td {
                                            vertical-align: middle;
                                            color: #444;
                                            font-size: 15px;
                                            text-align: center;
                                            padding: 10px 0;

                                            &:nth-child(3) {
                                                min-width: 170px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .address {
                            .card {

                                // border: none !important;
                                .card-body {
                                    padding: 0px;
                                }
                            }

                            .edit-address {
                                border: none;
                            }
                        }
                    }
                }

                .cart-price-detail {
                    padding: 10px 0px;
                    border-top: 2px solid $border-color;

                    .pre-invoice {
                        color: #2993ec;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 20px;
                        display: block;
                        text-align: right;
                        margin-top: 10px;
                    }

                    .change-step {
                        .next-step {
                            width: 320px;
                        }
                    }

                    .prices {
                        .item {
                            display: flex;
                            align-items: baseline;
                            justify-content: flex-end;
                            margin-bottom: 10px;

                            .title {
                                font-size: 16px;
                                font-weight: bold;
                            }

                            &.total {
                                margin-bottom: 0;

                                .price {
                                    font-size: 28px;
                                }
                            }

                            .price {
                                margin: 0px 5px;
                            }

                            &.wallets-box {
                                margin: 20px 0 0;
                                flex-flow: column;
                                align-items: flex-start;

                                .form-check {
                                    align-items: center;
                                    display: flex;
                                    margin-bottom: 15px;

                                    .form-check-label {
                                        font-size: 19px;
                                    }
                                }

                                #wallet-collapse {
                                    width: 100%;

                                    .wallet-item {
                                        align-items: center;
                                        margin-bottom: 1rem;

                                        .wallet-item-title {
                                            font-size: 15px;
                                        }

                                        .form-control {
                                            padding-top: 0.6rem;
                                            padding-bottom: 0.6rem;
                                        }

                                        .refresh-icon {
                                            font-size: 1rem;
                                            color: $primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .cart-payment-methods {
                    .cart-price-detail {
                        border: none;

                        .change-step {
                            order: 1;
                        }

                        .prices {
                            .item {
                                justify-content: flex-start;
                            }
                        }
                    }
                }

                .cart-heading-text {
                    font-weight: bold;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    text-align: center;
                }

                .cart-splited-items {
                    .discount-code {
                        .input-group {
                            input {
                                border-radius: 0px 30px 30px 0px !important
                            }

                            button {
                                margin-right: -20px;
                                z-index: 3;
                                font-size: 12px !important;
                                padding: 0.6rem 1rem !important;
                                font-weight: 800;
                            }
                        }
                    }

                    .splited-order {
                        padding: 20px 0px;
                        border-top: 2px solid #efefef;

                        .seller-name {
                            font-size: 15px;
                            font-weight: bold;
                            margin-bottom: 30px !important;
                            text-align: center;
                        }


                        .description-box {
                            padding-top: 20px;
                            border-top: 2px solid $border-color;

                            .title {
                                font-size: 15px;
                                margin-bottom: 10px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {
        .why-trust {
            .accordion {
                .accordion-item {
                    .accordion-body {
                        .item {
                            padding: 30px 50px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .container {
            max-width: 100% !important;
        }

        main {
            .cart-holder {
                border-radius: 10px !important;

                >.card-body {
                    .cart-container {
                        width: 100%;

                        .card-body {
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .empty-cart {
            margin-top: 56px;
        }

        main {
            padding-top: 77px;

            .cart-holder {
                >.card-body {
                    padding: 50px 15px 15px;

                    .cart-container {
                        .card-body {
                            .cart-products {
                                .cart-item-image {
                                    img {
                                        height: 40px;
                                        width: 40px;
                                    }
                                }

                                .cart-item-count-box {
                                    width: 130px;
                                    padding: 9px 7px;
                                    flex-flow: column;

                                    p {
                                        margin-top: 5px !important;
                                    }
                                }
                            }

                            .cart-send-methods {
                                .cart-price-detail {
                                    .pre-invoice {
                                        margin-bottom: 10px;
                                    }

                                    .prices {
                                        .item {
                                            justify-content: flex-start;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .cart-price-detail {
                        padding-top: 15px;
                        padding-bottom: 0px;

                        .change-step {
                            order: 1;

                            .next-step {
                                margin-top: 20px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .cart-header {
            padding: 15px 0;
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 5;
            box-shadow: 0 -1px 15px rgb(0 0 0 / 25%);
            transition: top .2s ease-in-out;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.hide {
                top: -56px;
            }

            a {
                width: 150px;
                height: 30px;
            }
        }
    }

    @media (max-width: 576px) {
        main {
            .cart-holder {
                margin-top: 30px;
                box-shadow: none;
                background-color: transparent;

                >.card-body {
                    padding: 0px;

                    .cart-container {
                        .card-body {
                            .cart-products {
                                .cart-item-title {
                                    padding-left: 30px;
                                }

                                .cart-item-price {
                                    font-weight: normal;
                                    font-size: 16px;
                                }

                                table {
                                    width: 100%;

                                    thead {
                                        display: none;
                                    }

                                    tbody {
                                        tr {
                                            position: relative;
                                            border-top: none;
                                            border-bottom: 1px solid $border-color;

                                            &:first-child {
                                                border: none;
                                            }

                                            td {
                                                &:nth-child(1) {
                                                    position: absolute;
                                                    left: 0;
                                                    top: 0;
                                                }

                                                &:nth-child(2) {
                                                    width: 100%;
                                                    padding: 0;
                                                    display: block;
                                                }

                                                &:nth-child(3) {
                                                    min-width: unset;
                                                    display: block;
                                                    float: right;
                                                }

                                                &:nth-child(4) {
                                                    display: block;
                                                    float: left;
                                                    padding-bottom: 0;
                                                    padding-top: 15px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .cart-price-detail {
                        .pre-invoice {
                            font-size: 13px;
                        }

                        .prices {
                            .item {
                                .title {
                                    font-size: 13px;
                                }

                                &:last-child {
                                    .price {
                                        font-size: 17px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
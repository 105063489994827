#head_header {
    background: #fff;
    padding-bottom: 6px;

    .offconvas-icon {
        position: absolute;
        right: 15px;
        top: 24px;
        display: none;
    }

    @media (max-width: 992px) {
        .offconvas-icon {
            display: block;
        }
    }

    @media (max-width: 620px) {
        height: 100px;
        padding-bottom: 0;
    }
}

.left-menu-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
}

.left-panel.v2 b {
    font-weight: 700;
}

.left-panel.v2 .top-links {
    width: 100%;
}

.left-panel.v2 .top-links,
.left-panel.v2 .cat-links {
    border-bottom: 1px solid #eee;
}

.left-panel.v2 .other-links {
    padding: 0;
    display: none;
}

.left-panel.v2 .other-links>li {
    padding: 20px 0;
}

.left-panel.v2 .top-links a {
    display: inline-block;
}

.left-panel.v2 .social-links {
    display: none;
}

.left-panel.v2 ul {
    padding: 20px 0;
    text-align: right;
    font-size: 13px;
    margin: 0;
}

.left-panel.v2>ul {
    border-bottom: 1px solid #efefef;
}

.left-panel.v2 .top-links li {
    width: 100%;
    display: inline-block;
}

.left-panel.v2 li a:hover {
    font-weight: 700;
}

.left-panel.v2 li a:hover .vnicon-telegram {
    color: #08c;
}

.left-panel.v2 li a:hover .vnicon-aparat {
    color: #df0f50;
}

.left-panel.v2 li a:hover .vnicon-linkedin {
    color: #2567b4;
}

.left-panel.v2 li a:hover .vnicon-instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.left-panel.v2 ul li a {
    line-height: 24px;
    color: #707070;
}

.left-panel.v2 .cat-links li {
    margin-top: 15px;
}

.left-panel.v2 .cat-links>li:first-child {
    margin-top: 0;
}

.left-panel.v2 .cat-links li a,
.left-panel.v2 .cat-links li span {
    position: relative;
    padding-right: 19px;
    cursor: pointer;
    display: block;
    line-height: 19px;
}

.left-panel.v2 .cat-links li a:hover,
.left-panel.v2 .cat-links li span:hover,
.left-panel.v2 .cat-links li.active>span {
    font-weight: 700;
    color: #58595b;
}

.left-panel.v2 .cat-links li span:before {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    font-family: "VN";
    font-weight: 300 !important;
}

.left-panel.v2 .cat-links li.active>span:before {
    color: #e1384d;
    content: "";
}

.left-panel.v2 .cat-links li>ul {
    padding: 0 25px 0 0;
    margin-top: 2px;
    display: none;
}

.left-panel.v2 .cat-links li>ul>li ul {
    padding: 0 25px 0 0;
    margin-top: 2px;
}

.left-panel.v2 .cat-links li>ul>li ul a,
.left-panel.v2 .cat-links li>ul>li>ul span {
    padding-right: 10px;
    display: block;
}

.left-panel.v2 .cat-links li>ul>li ul a:before,
.left-panel.v2 .cat-links li>ul>li>ul span:before {
    display: none;
}

.left-panel.v2 .cat-links li.active>ul {
    display: block;
}

.left-panel.v2 .social-links {
    text-align: center;
    padding-bottom: 70px;
}

.left-panel.v2 .social-links li {
    display: inline-block;
}

.left-panel.v2 .social-links li:nth-child(2n) {
    margin: 0 40px;
}

.left-panel.v2 .cat-links li span.morecat:before {
    content: "";
    font-family: "VN";
}

.left-panel.v2 .cat-links li a.view-all-menu,
.left-panel.v2 .cat-links li a.last-view-all-menu {
    color: #e15d63;
}

.left-panel.v2 .cat-links li a.view-all-menu {
    padding-right: 0;
}

#header-offconvas {
    .offcanvas-body {
        padding: 0px 30px 30px;

        header {
            font-size: 13px;
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;

            .pulse {
                display: inline-block;
                margin-left: 3px;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: rgba(16, 172, 132, 1);
                cursor: pointer;
                box-shadow: 0 0 0 rgba(16, 172, 132, 0.5);
                animation: pulse 1.5s infinite;
            }
        }

        .left-panel.v2 {
            background-color: #fff;
            margin-bottom: 10px;

            li {
                &::marker {
                    display: none !important;
                }
            }

            ul {
                padding: 0px;
                text-decoration: none;
                list-style: none;

                li {
                    &::marker {
                        display: none !important;
                    }

                    &.active {
                        >ul {
                            display: block !important;
                        }
                    }
                }
            }
        }

        .links {
            display: flex;
            flex-flow: column;
            align-items: flex-start;

            a {
                padding: 17px 7px;

                &:hover {
                    color: $primary !important;
                    font-weight: 700;
                }
            }

            .provider {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                &:hover {
                    a {
                        font-weight: 700;
                    }
                }

                a {
                    color: $primary !important;
                    border: 1px solid $primary !important;
                    border-radius: 2.5px;
                    padding: 10px;
                }
            }
        }

        .left-panel.v2 b {
            font-weight: 700;
        }

        .left-panel.v2 .top-links {
            width: 100%;
        }

        .left-panel.v2 .top-links,
        .left-panel.v2 .cat-links {
            border-bottom: 1px solid #eee;
        }

        .left-panel.v2 .other-links>li {
            padding: 20px 0;
        }

        .left-panel.v2 .top-links a {
            display: inline-block;
        }

        .left-panel.v2 ul {
            padding: 20px 0;
            text-align: right;
            font-size: 13px;
            margin: 0;
        }

        .left-panel.v2>ul {
            border-bottom: 1px solid #efefef;
        }

        .left-panel.v2 .top-links li {
            width: 100%;
            display: inline-block;
        }

        .left-panel.v2 li a:hover {
            font-weight: 700;
        }

        .left-panel.v2 li a:hover .vnicon-telegram {
            color: #08c;
        }

        .left-panel.v2 li a:hover .vnicon-aparat {
            color: #df0f50;
        }

        .left-panel.v2 li a:hover .vnicon-linkedin {
            color: #2567b4;
        }

        .left-panel.v2 li a:hover .vnicon-instagram {
            background: #f09433;
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .left-panel.v2 ul li a {
            line-height: 24px;
            color: #707070;
        }

        .left-panel.v2 .cat-links li {
            margin-top: 15px;
        }

        .left-panel.v2 .cat-links>li:first-child {
            margin-top: 0;
        }

        .left-panel.v2 .cat-links li a,
        .left-panel.v2 .cat-links li span {
            position: relative;
            padding-right: 19px;
            cursor: pointer;
            display: block;
            line-height: 19px;
        }

        .left-panel.v2 .cat-links li a:hover,
        .left-panel.v2 .cat-links li span:hover,
        .left-panel.v2 .cat-links li.active>span {
            font-weight: 700;
            color: #58595b;
        }

        .left-panel.v2 .cat-links li span:before {
            position: absolute;
            right: 0;
            top: 0;
            content: "";
            font-family: "VN";
            font-weight: 300 !important;
        }

        .left-panel.v2 .cat-links li.active>span:before {
            color: #e1384d;
            content: "";
        }

        .left-panel.v2 .cat-links li>ul {
            padding: 0 25px 0 0;
            margin-top: 2px;
            display: none;
        }

        .left-panel.v2 .cat-links li>ul>li ul {
            padding: 0 25px 0 0;
            margin-top: 2px;
        }

        .left-panel.v2 .cat-links li>ul>li ul a,
        .left-panel.v2 .cat-links li>ul>li>ul span {
            padding-right: 10px;
            display: block;
        }

        .left-panel.v2 .cat-links li>ul>li ul a:before,
        .left-panel.v2 .cat-links li>ul>li>ul span:before {
            display: none;
        }

        .left-panel.v2 .cat-links li.active>ul {
            display: block;
        }

        .left-panel.v2 .social-links {
            text-align: center;
            padding-bottom: 70px;
        }

        .left-panel.v2 .social-links li {
            display: inline-block;
        }

        .left-panel.v2 .social-links li:nth-child(2n) {
            margin: 0 40px;
        }

        .left-panel.v2 .cat-links li span.morecat:before {
            content: "";
            font-family: "VN";
        }

        .left-panel.v2 .cat-links li a.view-all-menu,
        .left-panel.v2 .cat-links li a.last-view-all-menu {
            color: #e15d63;
        }

        .left-panel.v2 .cat-links li a.view-all-menu {
            padding-right: 0;
        }
    }
}

.header-top {
    padding: 0 5px;
    position: relative;

    .cart-user {
        float: left;
        margin: 20px 20px 0 0;
        position: relative;
        width: auto;

        a {
            display: flex;
            align-items: center;
            position: relative;

            &:hover {
                color: $primary !important;
            }

            .cart-badge {
                position: absolute;
                top: -9px;
                right: -9px;
                padding: .2rem .3rem .1rem;
                font-size: 12px;
            }

            p {
                font-size: 12px;
                font-weight: 300;
                color: inherit;
                float: left;
                line-height: 26px;
            }

            i {
                font-size: 18px;
                color: inherit;
                margin-left: 4px;
            }
        }

        @media (max-width: 576px) {
            margin-top: 16px;

            a {
                p {
                    display: none;
                }
            }
        }
    }
}

.left-menu-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
}

#popularSearchBox {
    display: none;
}

#hamburger {
    display: none;
}

[for="hamburger"] {
    display: none;
    width: var(--hamburger_w);
    height: calc(var(--hamburger_h) * 3 + var(--hamburger_mBetweenLine) * 2);
    position: relative;
    cursor: pointer;
    transition: 0.4s;
    margin: 23px 10px;
}

hamburger-line {
    display: block;
    width: var(--hamburger_w);
    height: var(--hamburger_h);
    background: #5d5d5d;
    border-radius: var(--hamburger_halfH);
    position: absolute;
    left: 0;
}

.hamburger-line:nth-of-type(1),
.hamburger-line:nth-of-type(3) {
    transition: transform 0.2s, top 0.2s 0.2s;
}

.hamburger-line:nth-of-type(1) {
    top: 0;
}

.left-menu-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
}

.head_logo_a {
    width: 206px;
    height: 56px;
    // background-repeat: no-repeat !important;
    // background-size: contain !important;
    // background-position: -1px 9px !important;
    float: right;
    display: inline-table;
    padding-top:5px ;

    img {
        max-width: 206px;
        max-height: 56px;
    }
}

.navbar-nav .login-signup-link {
    display: none !important;
}

.login-signup-link {
    float: left;
    margin: 20px 20px 0 0;
}

.login-signup-link i {
    font-size: 24px;
    color: #888;
}

.login-signup-link span {
    font-size: 13px;
}

.header-top .basket {
    position: relative;
    float: left;
    margin: 20px 20px 0 0;
}

.header-top .basket .vnicon-basket {
    color: #888;
    font-size: 28px;
}

.header-top .basket span {
    width: 20px;
    height: 20px;
    border: solid 1px #e1384d;
    background-color: #fff;
    position: absolute;
    top: -7px;
    right: -3px;
    border-radius: 100%;
    text-align: center;
    line-height: 21px;
    color: #666;
}

.header-top .header-account:hover a {
    color: #58595b;
}

.header-top .header-account:hover p {
    color: #e1384d;
}

.header-top .header-account {
    float: left;
    margin: 20px 20px 0 0;
    position: relative;
    width: auto;
}

.header-top .header-account .vnicon-user {
    font-size: 24px;
}

.header-accont-body .simple-btn.sign-out {
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    padding: 15px 16px;
    text-align: center;
    display: block;
    background-color: $primary;
}

.header-accont-body .simple-btn.sign-out:hover {
    background-color: #ea0c29;
}

.login-section {
    padding: 5px 5px 5px 15px;
    border-radius: 7px;
    font-size: 13px;
    cursor: pointer;
}

.header-account .header-account-top {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: url(https://static.vitrinnet.com/img/home-new/vector-accont.svg) no-repeat 50% 100%;
    color: #111;
    font-size: 1.8em;
    font-weight: 300;
    line-height: 21px;
    margin: 16px 0 15px;
    padding: 0 15px;
    margin-bottom: 15px;
    padding-bottom: 86px;
    margin-top: 0;
}

.header-accont-body .simple-btn.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    height: 44px;
    font-size: 15px;
    letter-spacing: 0.5px;
    display: block;
    line-height: 44px;
    font-weight: 700;
    border-radius: 2.5px;
}

.header-accont-body .simple-btn.btn-success:hover {
    background-color: #1a6d2d;
}

.header-account-dd {
    font-size: 10px;
    visibility: hidden;
    margin-left: -31px !important;
    text-align: center;
    padding: 24px;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #e2e4e8;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    left: 24%;
    min-height: 50px;
    position: absolute;
    top: 57px;
    width: 250px;
    z-index: 10;
    transition: 0.3s;
    top: 30px;
    opacity: 0;
}

.header-account-dd:before {
    content: "";
    display: block;
    height: 10px;
    left: 50%;
    margin-left: -46px;
    position: absolute;
    top: -10px;
    width: 92px;
}

.header-dd-hover-element:hover .header-account-dd {
    visibility: visible;
    top: 46px;
    opacity: 1;
}

.header-account .header-account-top {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: url(https://static.vitrinnet.com/img/home-new/vector-accont.svg) no-repeat 50% 100%;
    color: #111;
    font-size: 1.8em;
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 15px;
    padding-bottom: 86px;
    margin-top: 0;
}

.nav-tool-wrap {
    float: left;
    padding-top: 22px;
}

.nav-tool-wrap .header-dd-h {
    top: 82px;
}

.nav-tool {
    color: #3d4044;
    cursor: pointer;
    display: block;
    font-size: 11px;
    line-height: 1;
    min-width: 42px;
    padding: 0 10px;
    position: relative;
    text-align: center;
}

.nav-tool:hover {
    text-decoration: none;
}

.nav-tool:hover .count-item {
    background-color: #ce4e45;
}

.nav-tool>.icon {
    left: 50%;
    margin-left: -20px;
    position: absolute;
    top: -2px;
}

.nav-tool>.icon.-icon-wishlist i {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 28px;
    padding: 7px 0 0;
}

.nav-tool>.icon.-icon-account {
    text-indent: 0;
    top: 2px;
    left: 5px;
    margin-left: 3px;
    display: none;
}

.nav-tool.-account.-arrow:before {
    border-bottom-color: #e2e4e8;
}

.nav-tool.-account.-arrow:after {
    border-bottom-color: #fff;
}

.nav-tool.-account.-arrow:before,
.nav-tool.-account.-arrow:after {
    content: "";
    cursor: default;
    opacity: 0;
    position: absolute;
    bottom: -18px;
    -webkit-transition: opacity 0.1s ease 0.2s, visibility 0 ease 0.3s;
    transition: opacity 0.1s ease 0.2s, visibility 0 ease 0.3s;
    visibility: hidden;
}

.nav-tool.-account.-arrow:before {
    border-bottom: 12px solid #ddd;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    left: 50%;
    margin-left: -12px;
    margin-top: 4px;
}

.nav-tool.-arrow:after {
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: 50%;
    margin-left: -10px;
    margin-top: 6px;
    z-index: 22;
}

.nav-tool.-arrow.-account:before {
    margin-top: 1px;
}

.nav-tool.-arrow.-account:after {
    margin-top: 3px;
}

.header-dd-hover-element:hover>.nav-tool.-arrow::after,
.header-dd-hover-element:hover>.nav-tool.-arrow::before {
    opacity: 1;
    visibility: visible;
}

.header-account .header-sign-up {
    margin: 15px 0;
    font-size: 13px;
}

.header-account .header-sign-up a {
    color: #3761bf !important;
}

.header-account .header-sign-up a:hover {
    color: $primary !important;
}

.header-search-btn .search-field {
    width: 100%;
    height: 100%;
}

.header-search-btn .search-field .search-res-parent:empty {
    display: none;
}

.header-search-btn .search-input {
    color: #666;
    border: #f0f0f1;
    font-weight: 300;
    width: 100%;
    height: 100%;
    background: #f0f0f1;
    padding: 0 5px;
    outline: none;
}

.popular-title {
    text-align: right;
    color: #909090;
    font-size: 12px;
    font-weight: 700;
}

.header-search-btn {
    height: 35px;
    background-color: #f0f0f1;
    border: none;
    border-radius: 2.5px !important;
    cursor: pointer;
    display: block;
    padding: 5px 27px 5px 10px !important;
    position: relative;
    top: 15px;
    float: right;
    margin-right: 40px;
    min-width: 37.5%;
    z-index: 1;

    .search-icon {
        position: absolute;
        right: 12px;
        top: 12px;
        font-weight: 700;
        font-size: 12px;
        color: #999;
        outline: none;
        z-index: 2;
    }

    &.active {
        z-index: 20;

        .search-field {
            .search-res-parent {
                display: flex;
                flex-flow: column;
                gap: 10px;
            }
        }
    }

    .search-field {
        width: 100%;
        height: 100%;

        .search-res-parent {
            padding: 13px 15px;
            background-color: #fff;
            position: relative;
            z-index: 3001;
            top: 34px;
            border: solid 1px #e4e4e4;
            border-radius: 0 0 10px 10px;
            position: absolute;
            margin: 0;
            left: 0;
            right: 0;
            list-style: none;
            display: none;

            .loading {
                height: 150px;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
            }

            .spacer {
                height: 1px;
                background-color: #999;
            }

            .search-result {
                padding: 4px 0;
                a {
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;
                    width: 100%;

                    i {
                        font-size: 21px;
                        color: #999;
                    }

                    .text-holder {
                        display: flex;
                        flex-flow: column;
                        gap: 5px;

                        .title {
                            font-size: 14px;
                            color: $dark;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.search-bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 24;
    display: none;
}

.header-search-btn .search-field .search-res-parent.dn {
    display: none !important;
}

.header-search-btn .search-field .search-res-parent:empty {
    display: none;
}

.header-search-btn .search-field .search-res-parent .liveSR:first-child {
    height: auto;
}

.header-top .header-search-btn .search-field .search-res-parent .liveSR {
    line-height: 22px;
    width: 100%;
    float: right;
    background-color: #fff;
    z-index: 1000;
    position: relative;
    font-size: 13px;
    padding-bottom: 10px;
}

.cat-in-search:before {
    position: absolute;
    right: 0;
    color: #888;
    top: 0;
    transform: rotate(180deg);
}

.header-search-btn .search-field .search-res-parent .liveSR.cat-in-search span {
    position: relative;
    float: right;
    font-family: "iranyekan-regular";
}

.header-search-btn .search-field .search-res-parent .liveSR.cat-in-search span:before {
    content: "";
    font-family: "VN";
    font-weight: 600;
    position: absolute;
    left: -15px;
    font-size: 15px;
    color: #7c7c7e;
    top: 2px;
}

.cat-in-search {
    padding-right: 20px;
}

// .fa-th-large:before {
//     font-size: 13px;
//     font-family: "Font Awesome 5 Free";
//     font-weight: 600;
// }

.header-search-btn>.search-field>.icon {
    background: #e1384d;
    z-index: 10001;
    border-radius: 50px;
    position: absolute;
    left: 5px;
    top: 4px;
    width: 125px;
    height: 32px;
    color: #fff;
    text-align: center;
}

.header-search-btn>.search-field>.icon>i {
    left: 23px;
    position: absolute;
    top: 9px;
}

.header-top .header-search-btn .search-field .search-res-parent .product-in-search {
    height: auto;
    padding: 10px 0;
    border-bottom: 1px solid #efefef;
}

.header-top .header-search-btn .search-field .search-res-parent .product-in-search:last-child {
    border-bottom: 0 none;
}

.header-top .header-search-btn .search-field .search-res-parent .product-in-search img {
    float: right;
    width: 20%;
    border: solid 1px #efefef;
    border-radius: 5px;
    padding: 2px;
}

.header-top .header-search-btn .search-field .search-res-parent .product-in-search a>div>span {
    color: #58595b;
    width: 100%;
    display: block;
    font-size: 13px;
    position: relative;
    padding-left: 80px;
}

.header-top .header-search-btn .search-field .search-res-parent .product-in-search a>div>span b {
    position: absolute;
    left: 0;
    top: 4px;
    font-size: 12px;
    width: 90px;
    line-height: 14px;
    color: #e1384d;
    letter-spacing: -0.4px;
    text-align: left;
}

.header-top .header-search-btn .search-field .search-res-parent .product-in-search a>div>span b span {
    display: initial;
    font-size: 11px;
    margin-right: 2px;
    color: #58595b;
}

.header-top .header-search-btn .search-field .search-res-parent .product-in-search .min-order {
    width: 100%;
    color: #707070;
    font-size: 12px;
}

.header-search-btn .search-field .search-res-parent .liveSR.category {
    border-bottom: 1px solid #efefef;
}

.header-search-btn .search-field .search-res-parent .liveSR a:hover {
    color: #58595b;
}

.header-top .header-search-btn .search-field .search-res-parent .liveSR.product-in-search:hover {
    background-color: rgba(242, 242, 242, 0.7);
}

.header-search-btn .search-field .search-res-parent .liveSR a {
    color: #58595b;
    width: 100%;
    float: right;
    font-weight: 400;
}

.header-search-btn .search-field .search-res-parent .liveSR.product-in-search a:hover span {
    color: #58595b;
}

.refine-border {
    border-radius: 20px 20px 0 0 !important;
}

.header-search-btn .search-field .search-res-parent:empty~.search-input.refine-border {
    border-radius: 50px !important;
}

.header-search-btn .search-field .search-res-parent:not(:empty)~.search-input:focus {
    border-radius: 20px 20px 0 0 !important;
}

.popular-title {
    text-align: right;
    color: #909090;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 10px;
}

a.popular-tag {
    border: 1px solid #4f74b3;
    display: inline-block;
    width: auto !important;
    padding: 5px 9px;
    margin: 4px;
    border-radius: 5px;
    font-size: 12px;
    background: #fff;
    color: #4f74b3 !important;
    float: right;
}

a.popular-tag:hover {
    color: #073b94 !important;
}

.navbar-main-menu {
    overflow: hidden;
}

.navbar {
    background: #fff;
    position: relative;
    z-index: 2;
    border-bottom: 1px solid #dcdbdb;
}

@media (max-width: 992px) {
    .navbar {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }
}

.navbar li a {
    display: block;
}

.navbar ul.navbar-nav>li {
    position: relative;
    padding: 18px;
    color: #58595b;
    font-size: 13px;
    display: inline-table;
    padding-right: 0;
    overflow: hidden;
}

.navbar ul.navbar-nav>li .menuIcone {
    position: relative;
}

.navbar ul.navbar-nav>li .menuIcone:before {
    content: "";
    position: absolute;
    left: 5px;
    top: -13px;
    right: auto;
    background: url(https://static.vitrinnet.com/img/new/new-icone.png);
    background-repeat: no-repeat;
    width: 20px;
    height: 15px;
}

.navbar ul.navbar-nav>li.has-sub-menu {
    overflow: visible;
}

.navbar ul.navbar-nav>li:nth-child(1)>a {
    padding-right: 0;
}

.navbar ul.navbar-nav>li>a:after {
    content: "";
    position: absolute;
    width: 660px;
    height: 15px;
    z-index: 5;
    display: block;
    right: 0;
    bottom: -15px;
}

.navbar ul.navbar-nav>li img.icone-menu,
.navbar ul.navbar-nav>li img.icone-menu-hover {
    width: 16px;
    vertical-align: middle;
    margin-left: 3px;
}

.navbar ul.navbar-nav>li img.icone-menu-hover {
    display: none;
}

.navbar ul.navbar-nav>li:hover img.icone-menu-hover {
    display: inline-table;
}

.navbar ul.navbar-nav>li:hover img.icone-menu {
    display: none;
}

.navbar ul.navbar-nav>li.provider {
    padding: 7px 0;
}

.navbar ul.navbar-nav>li.provider a {
    color: $primary !important;
    border: 1px solid $primary;
    border-radius: 2.5px;
    padding: 10px;
}

.navbar .sub-menu {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1000;
    background: #fff;
    width: max-content;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.navbar>.container {
    position: relative;
}

.has-menu-icon {
    position: static !important;
    padding-left: 0 !important;
}

.has-menu-icon #menuHtml {
    line-height: 45px;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
}

.has-menu-icon i {
    vertical-align: middle;
    padding: 2px 0 0 5px;
    font-size: 15px;
    color: #333;
}

.has-menu-icon>span {
    width: 124px;
    display: inline-block;
}

.has-menu-icon:hover>span {
    color: $primary;
    font-weight: 700;
    cursor: pointer;
}

.productMenu {
    &:hover {
        .mega-menu-container {
            display: flex;
        }
    }
}

.mega-menu-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 0 0 15px 15px;
    overflow: hidden;
    display: none;
    width: 100%;
}

.mega-menu-container a:hover {
    color: #e1384d;
}

.mega-menu-container.selected {
    display: block;
}

.mega-menu-category {
    width: 215px;
    display: inline-block;
}

.mega-menu-category a {
    display: flex !important;
    align-items: baseline;
    padding: 0 10px;
    border-bottom: 1px solid #eee;
    font-size: 12px;
    font-weight: 700;
    position: relative;
    padding-right: 20px;
    cursor: pointer;
}

.mega-menu-category a i {
    margin-right: 5px;
    font-size: 11px;
}

.mega-menu-category a:hover,
.mega-menu-category a.selected {
    background: #f5f5f5;
    color: $primary;
}

.mega-menu-category a:hover i,
.mega-menu-category a.selected i {
    color: $primary;
}

.mega-menu-items {
    min-height: 100%;
    flex-grow: 1;
    border-right: 1px solid #eee;
    padding: 0 10px;
}

.megamenu1.selected {
    display: block !important;
}

.has-menu-icon .wrap {
    position: absolute !important;
}

a.m-menu-item-more {
    font-size: 13px;
    line-height: 19px;
    display: block;
    color: #bbb !important;
    margin: 6px 0 10px;
}

.m-menu-item-more i {
    color: #e1384d;
    font-weight: 700;
    line-height: 1px;
    font-size: 13px;
}

a.m-menu-item-more:hover {
    color: #e1384d;
}

.megamenu1 {
    background-color: #fff;
    padding: 10px 0;
    width: 100%;
    display: none;
}

.megamenu1 .megaimg {
    width: 75px;
    height: 60px;
    position: absolute;
    left: 15px;
    top: 15px;
}

.prds-title {
    font-size: 13px;
    text-align: right;
    line-height: 18px;
    float: right;
    color: #58595b;
    padding: 0 15px 0 10px;
    position: relative;
    border-radius: 0;
    margin: 10px 0;
    clear: both;
    font-weight: 700;
}

.prds-title:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 22px;
    border-radius: 5px;
    top: -3px;
    right: 0;
    background-color: $primary;
}

.m-menu-item {
    display: block;
    font-size: 12px;
    margin: 5px 0;
    text-align: right;
    clear: both;
    color: #58595b !important;
    line-height: 22px;

    &:hover {
        color: $primary !important;
    }
}

.left-panel.v2 .pulse {
    display: inline-block;
    margin-left: 3px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: rgba(16, 172, 132, 1);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(16, 172, 132, 0.5);
    animation: pulse 1.5s infinite;
}

.left-panel.v2 .pulse:hover {
    animation: none;
}

@media (min-width: 992px) {
    .navbar .sub-menu {
        display: none;
    }

    .navbar ul.navbar-nav>li:hover .sub-menu {
        display: block;
    }

    .navbar ul.navbar-nav>li:hover .vnicon-top-arrow {
        transform: rotate(180deg);
    }
}

.navbar ul.navbar-nav>li:hover>a,
.navbar ul.navbar-nav>li:hover .sub-menu-title {
    color: $primary !important;
    font-weight: 700;
}

.navbar ul.navbar-nav>li.menuChild-1>a {
    width: 66px;
    text-align: center;
}

.navbar ul.navbar-nav>li.menuChild-50>a {
    width: 50px;
    text-align: center;
}

.navbar ul.navbar-nav>li.menuChild-2 .sub-menu-title {
    width: 88px;
    text-align: center;
}

.navbar ul.navbar-nav>li.menuChild-3 .sub-menu-title {
    width: 110px;
    text-align: center;
}

.navbar ul.navbar-nav>li.menuChild-4>a {
    width: 81px;
    text-align: center;
}

.navbar ul.navbar-nav>li.menuChild-5>a {
    width: 153px;
}

.navbar ul.navbar-nav>li.blogLink {
    display: none;
}

.navbar ul.navbar-nav>li.menuChild-6>a {
    // width: 197px;
    text-align: center;
}

.navbar ul.navbar-nav>li .sub-menu-title:hover,
.navbar ul.navbar-nav>li>a:hover {
    cursor: pointer;
}

.navbar ul.navbar-nav>li.submenu-right:hover,
.navbar ul.navbar-nav>li.submenu-left:hover {
    color: #58595b;
}

.navbar .sub-menu>ul>li:hover>a {
    color: $primary;
}

.carousel-control-prev,
.carousel-control-next {
    z-index: 0;
}

.navbar .sub-menu li {
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 400;
}

.navbar .sub-menu li.submenu-right,
.navbar .sub-menu li.submenu-left {
    margin-bottom: 0;
}

.navbar .sub-menu li.submenu-seeAll,
.navbar .sub-menu li.submenu-seeAll a {
    margin-bottom: 0 !important;
}

.navbar .sub-menu ul {
    padding-right: 0;
    display: inline-table;
    vertical-align: top;
}

.navbar .sub-menu .imageBox {
    padding-left: 0;
    display: inline-table;
    vertical-align: middle;
    text-align: left;
}

.navbar .sub-menu .imageBox img {
    max-width: 100%;
}

.navbar .sub-menu .seeAll {
    font-weight: 700;
}

.navbar .sub-menu .seeAll i {
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
}

.navbar .sub-menu2 .navbar-nav>li {
    vertical-align: top;
    min-width: 200px;
    display: inline-table;
}

.navbar .sub-menu2 .submenu-col2 {
    margin-right: 30px;
}

.navbar .sub-menu2 .navbar-nav>li ul li a:hover {
    color: $primary;
}

.navbar .sub-menu2 .navbar-nav>li ul li .navbar-nav-title {
    font-weight: 700;
    font-size: 14px;
}

.navbar .sub-menu2 .submenu-seeAll a {
    color: #a9a9a9;
}

.navbar-toggler {
    color: #999;
    position: absolute;
    padding: 0;
    right: 15px;
    cursor: pointer;
    display: none;
    top: 12px;
}

.blur .navbar-toggler .list-menu,
.blur-search .navbar-toggler .list-menu {
    opacity: 0;
}

.navbar-toggler .list-menu {
    font-size: 38px;
    margin-top: -1px;
}

.navbar-toggler .exit-menu {
    font-size: 23px;
    position: fixed;
    top: 10px;
}

.basket-text-mobile {
    display: none;
}

.navbar ul.navbar-nav .vnicon-top-arrow {
    display: none;
}

@media (max-width: 1200px) {
    .navbar .sub-menu2 {
        padding-bottom: 0;
    }

    .navbar .sub-menu .imageBox img {
        max-width: 350px;
    }
}

@media (max-width: 1020px) {
    .navbar .sub-menu2 .navbar-nav>li {
        min-width: 193px;
    }

    .navbar ul.navbar-nav>li {
        padding: 20px 15px;
    }
}

@media (max-width: 992px) {
    .navbar ul.navbar-nav>li.blogLink {
        display: block;
    }

    .navbar ul.navbar-nav>li .menuIcone:before {
        left: auto;
        right: 72%;
    }

    .navbar ul.navbar-nav>li a {
        display: inline-block;
    }

    .p-0-mobile {
        padding: 0 !important;
    }

    .navbar .has-menu-icon {
        display: none !important;
    }

    .login-signup-link {
        display: none;
    }

    .login-signup-link span {
        padding: 0 2px;
    }

    .navbar-nav .login-signup-link {
        display: block !important;
        margin: 0;
    }

    .navbar-nav .login-signup-link a {
        display: inline-table;
    }

    .navbar-nav .login-signup-link a i {
        margin-left: 5px;
    }

    .left-panel-show .left-panel.v2 {
        display: block !important;
        margin-bottom: 10px;
    }

    .head_logo_a {
        width: 140px;
        margin-right: 50px;
        background-position: -1px 17px !important;
    }

    .header-search-btn {
        margin-right: 15px;
        min-width: 45%;
    }

    .header-top .header-account {
        margin-right: 0;
    }

    .basket-text-ds {
        display: none;
    }

    .basket-text-mobile {
        display: inline-block;
    }

    .header-search-btn .search-input {
        width: 92%;
        display: block;
        position: absolute;
        background: transparent;
        top: 0;
    }

    .header-search-btn .search-field .search-res-parent a {
        display: block;
        width: 100%;
    }

    .blur .navbar {
        filter: blur(0);
    }

    .navbar ul.navbar-nav>li:nth-child(1)>a {
        width: auto;
        text-align: right;
    }

    .navbar ul.navbar-nav>li:nth-child(2) .sub-menu-title {
        width: auto;
        text-align: right;
    }

    .navbar ul.navbar-nav>li:nth-child(3) .sub-menu-title {
        width: auto;
        text-align: right;
    }

    .navbar ul.navbar-nav>li:nth-child(4)>a {
        width: auto;
        text-align: right;
    }

    .navbar ul.navbar-nav>li:nth-child(5)>a {
        width: auto;
        text-align: right;
    }

    .navbar ul.navbar-nav>li.menuChild-4>a {
        text-align: right;
    }

    .navbar ul.navbar-nav>li {
        padding: 15px 10px;
    }

    .navbar ul.navbar-nav>li.has-sub-menu .sub-menu {
        height: auto;
        transition: height 0.5s ease-in-out;
    }

    .navbar ul.navbar-nav>li.has-sub-menu .sub-menu * {
        height: auto;
        opacity: 1;
        transition: height 0.5s ease-in-out;
    }

    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu .sub-menu {
        padding: 0;
        height: 0;
    }

    .navbar .sub-menu2 .submenu-col2 {
        margin-right: 0;
    }

    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu .sub-menu * {
        padding: 0;
        height: 0;
        margin-bottom: 0;
        opacity: 0;
    }

    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu .vnicon-top-arrow {
        transform: rotate(180deg);
    }

    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu>a,
    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu {
        color: #58595b;
        font-weight: 400;
    }

    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu img.icone-menu-hover {
        display: none;
    }

    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu img.icone-menu {
        display: inline-table;
    }

    .navbar .sub-menu li.submenu-right {
        margin-bottom: 30px;
    }

    .navbar .sub-menu li.submenu-left {
        margin-bottom: 15px;
    }

    .navbar .sub-menu li {
        margin-bottom: 20px;
        font-size: 11px;
    }

    .closeSubMenu {
        background-color: transparent !important;
    }

    .navbar {
        top: 0;
        padding: 0;
        width: fit-content;
        border-bottom: 0;
    }

    .navbar .navbar-collapse {
        padding: 30px;
        position: absolute;
        top: 0;
        z-index: 21;
        background: #fff;
        right: 0;
        box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.5);
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        overflow: hidden;
        box-shadow: none;
    }

    .navbar .sub-menu .imageBox {
        display: none;
    }

    .navbar .sub-menu ul {
        display: block;
    }

    .navbar {
        z-index: 21;
    }

    .navbar .sub-menu {
        position: relative;
        box-shadow: none;
        width: 100%;
        top: 0;
        padding-top: 30px;
    }

    .navbar .sub-menu2 .navbar-nav>li {
        min-width: auto;
        display: block;
        padding: 0;
    }

    .navbar ul.navbar-nav .vnicon-top-arrow {
        font-size: 25px;
        position: relative;
        display: inline-table;
        vertical-align: middle;
        margin-bottom: 2px;
    }

    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu,
    .navbar ul.navbar-nav>li.has-sub-menu.closeSubMenu .sub-menu {
        background: transparent;
    }

    .navbar .sub-menu2 .submenu-right {
        margin-bottom: 30px;
    }

    .navbar ul.navbar-nav>li.provider {
        width: fit-content;
        margin: 0 auto;
        display: block;
    }

    .navbar-toggler {
        display: block;
    }

    .is-burger-active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .navbar-toggler .exit-menu {
        display: none;
    }

    .navbar-toggler[aria-expanded="true"] {
        z-index: 22;
    }

    .navbar-toggler[aria-expanded="true"] .exit-menu {
        display: block;
    }

    .navbar-toggler[aria-expanded="true"] .list-menu {
        display: none;
    }

    .navbar-toggler {
        display: block;
    }

    .navbar {
        width: 400px;
        position: fixed;
        top: 0;
        right: -100%;
        height: 100%;
        transition: 0.3s;
        display: block;
    }

    .is-burger-active .navbar {
        background: #fff;
        right: 0;
        overflow: auto;
    }

    .is-burger-active .navbar-nav {
        height: auto;
    }

    .is-burger-active .navbar-toggler {
        top: 10px;
        right: 10px;
    }

    .navbar li a {
        font-size: 13px;
    }
}

@media (max-width: 900px) {
    .navbar .sub-menu2 .imageBox {
        width: 360px;
    }

    .navbar .sub-menu2 .navbar-nav>li {
        min-width: 175px;
    }
}

@media (max-width: 840px) {
    .header-top .basket {
        margin-right: 10px;
    }
}

@media (max-width: 718px) {
    .header-search-btn {
        margin-right: 20px;
        min-width: 37%;
    }
}

@media (max-width: 648px) {
    .header-search-btn {
        min-width: 32%;
    }
}

@media (max-width: 620px) {
    .header-top .header-account {
        margin-top: 16px;
    }

    .header-top .basket {
        margin-top: 16px;
    }

    .navbar-toggler .list-menu {
        font-size: 35px;
    }

    .header-search-btn {
        min-width: 100%;
        margin: 0;
        float: none;
        top: 52px;
    }

    .header-top {
        padding: 0;
    }

    .navbar-toggler {
        top: 10px;
    }

    .head_logo_a {
        background-position: 0px 15px !important;
        width: 130px;
    }
}

@media (max-width: 480px) {
    .header-top .basket a div {
        display: none !important;
    }

    .navbar {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .login-section {
        padding-left: 0;
    }
}

@media (max-width: 340px) {
    .header-top .header-account .vnicon-user {
        font-size: 22px;
    }

    .header-top .basket {
        margin-top: 15px;
        margin-right: 5px;
    }

    .head_logo_a {
        margin-right: 42px;
    }
}

.baner-header {
    background: #e1384d;
    display: block;
    width: 100%;
    background-position: center;
    background: url(https://static.vitrinnet.com/img/aghsati-bg.jpg);
    background-size: cover;
    padding: 10px 0;
}

.baner-header img {
    display: block;
    margin: 0 auto;
}

.baner-header .baner-header-smallPhone {
    /*display:none;*/
    color: #fff;
    text-align: center;
}

.baner-header .baner-header-smallPhone .font-weight-bold {
    font-size: 15px;
    display: inline-block;
}

.baner-header .baner-header-smallPhone .baner-header-button {
    color: #e1384d;
    background: #fff;
    border-radius: 15px;
    width: 225px;
    padding: 5px 0;
    text-align: center;
    font-weight: bold;
    display: inline-block;
}

@media (max-width: 680px) {
    .baner-header .baner-header-smallPhone .baner-header-button {
        margin: 10px auto 0;
        display: block;
        text-align: center;
    }

    .baner-header .baner-header-smallPhone .font-weight-bold {
        display: block;
        text-align: center;
    }
}

@media (max-width: 430px) {

    /*.baner-header img{display:none !important}*/
    .baner-header .baner-header-smallPhone .font-weight-bold {
        letter-spacing: -0.5px;
        font-size: 13px;
    }

    .baner-header .baner-header-smallPhone .baner-header-button {
        letter-spacing: -0.5px;
        font-size: 13px;
        width: 215px;
    }
}

@media (max-width: 340px) {

    /*.baner-header img{display:none !important}*/
    .baner-header .baner-header-smallPhone .font-weight-bold {
        font-size: 12px;
    }

    .baner-header .baner-header-smallPhone .baner-header-button {
        font-size: 12px;
        width: 190px;
    }
}
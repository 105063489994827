// Color system
//
// Black color
$dark: $custom_dark !default;
$black: $custom_black !default;
// White color
$white: $custom_white !default;
// Gray colors
$gray-100: $custom_gray_100 !default;
$gray-200: $custom_gray_200 !default;
$gray-300: $custom_gray_300 !default;
$gray-400: $custom_gray_400 !default;
$gray-500: $custom_gray_500 !default;
$gray-600: $custom_gray_600 !default;
$gray-700: $custom_gray_700 !default;
$gray-800: $custom_gray_800 !default;
$gray-900: $custom_gray_900 !default;
// Text muted
$text-muted: $gray-500 !default; // Custom variable
$text-balck: $black !default; // Custom variable
$text-dark: $dark !default; // Custom variable
// Gray colors list
$grays: ("100": $gray-100, "200": $gray-200, "300": $gray-300, "400": $gray-400, "500": $gray-500, "600": $gray-600, "700": $gray-700, "800": $gray-800, '900': $gray-900) !default; // Custom variable
// Bootstrap standard & extended colors
// Dark colors
$dark: $dark !default; // Bootstrap variable
// Primary colors
$primary: $custom_primary !default; // Bootstrap variable
$dark-primary: $custom_dark_primary !default; // Bootstrap variable
// Secondary colors
$secondary: $gray-300 !default; // Bootstrap variable
$dark-secondary: $gray-400 !default; // Bootstrap variable
// Success colors
$success: $custom_success !default; // Bootstrap variable
$dark-success: $custom_dark_success !default; // Bootstrap variable
// Inco colors
$info: $custom_info !default; // Bootstrap variable
$dark-info: $custom_dark_info !default; // Bootstrap variable
// Warning colors
$warning: $custom_warning !default; // Bootstrap variable
$dark-warning: $custom_dark_warning !default; // Bootstrap variable
// Danger colors
$danger: $custom_danger !default; // Bootstrap variable
$dark-danger: $custom_dark_danger !default;
// Light colors
$light: $gray-100 !default; // Bootstrap variable
// Bootstrap theme colors
$theme-colors: ("white": $white, // custom color type
"primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark, );
// Extended theme text colors
$theme-text-colors: ("white": $white, "primary": $primary, "secondary": $dark, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark) !default; // Custom variable
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 600;
$border-radius: 4px;
$card-border-radius: 6px;
$border-color: $gray-200;
.accordion {
    .accordion-item {
        border-color: $border-color;
        .accordion-header {
            .accordion-button {
                border-color: transparent;
                box-shadow: none !important;
                color: $dark !important;
                text-align: right;
                &::after {
                    background-image: none;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f078";
                    font-size: 14px;
                    color: $text-muted;
                    margin-left: 0;
                    margin-right: auto;
                }
                &:not(.collapsed) {
                    color: initial;
                    background-color: initial;
                    box-shadow: none;
                    &::after {
                        transform: rotateX(180deg);
                    }
                }
            }
        }
    }
}
// .Toastify__toast-theme--colored.Toastify__toast--success {
//     color: $white;
//     background: $success;
// }
// .Toastify__toast-theme--light {
//     background: $danger;
//     color: $white;
// }
.Toastify__toast-icon,
.toast_close_button {
    color: $white !important;
}

.toast_icons {
    color: $white;
}

.toast_close_button {
    margin-top: 14px;
}
#static-page {
    .card {
        .card-body {
            p {
                margin-bottom: 15px !important;
            }
            figure {
                img {
                    max-width: 100%;
                    display: block;
                    margin: auto;
                }
            }
        }
    }
}
.slider {
    position: relative;
    width: 100%;
    z-index: 0;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
    position: absolute;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
}

.slider__range {
    background-color: #9fe5e1;
    z-index: 2;
}


/* Removing the default appearance */

.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
}

.thumb--zindex-1 {
    z-index: 1;
}

.thumb--zindex-2 {
    z-index: 2;
}


/* For Chrome browsers */

.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}


/* For Firefox browsers */

.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

.slider__left-value,
.slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
    position: absolute;
}

.slider__left-value {
    left: 6px;
}

.slider__right-value {
    right: -4px;
}

.from-to-price {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 12px;
    input {
        border-radius: 4px;
        border: solid 2px #e4e4e4;
        padding: 5px 0;
        text-align: center;
        width: 48%;
    }
}
.card {
    background-color: $white;
    border-radius: $border-radius !important;
    border-color: $border-color !important;
    &.no-border {
        border: none !important;
    }
    &.box-shadow {
        box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%);
    }
    &.no-radius {
        border-radius: 0px !important;
    }
    &.dark-border {
        border-color: rgba(26, 28, 30, 0.125) !important;
    }
    &.black-border {
        border-color: $black !important;
    }
    .card-body {
        padding: 10px;
    }
    &.dark-gradient {
        &:hover {
            background-image: linear-gradient( 167deg, #1b1c1d 37%, gray 99%);
            span {
                color: $white;
            }
        }
        .card-body {
            padding: 23px 20px;
            display: flex;
            align-items: center;
            .icon-holder {
                width: 52px;
                line-height: 52px;
                height: 52px;
                background: #9dffb4;
                border-radius: 50%;
                text-align: center;
                margin-left: 15px;
                img {
                    width: 24px;
                    &.white {
                        filter: brightness(0) invert(1);
                    }
                }
            }
            span {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
}
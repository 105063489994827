.modal {
    .modal-header {
        &.no-border {
            border: none;
        }
        &.absolute-close {
            .btn-close {
                position: absolute;
                top: 1rem;
                right: 1rem;
            }
        }
    }
}
#product-detail {
    .mobile-header {
        padding: 15px 0;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        box-shadow: 0 -1px 15px rgb(0 0 0 / 25%);
        transition: top .2s ease-in-out;
        background: #fff;
        padding: 0px 15px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 20px;
        }

        &.hide {
            top: -56px;
        }
    }

    .share-section {
        .share-section-content {
            background: #fff;
            padding: 15px 15px 5px;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            position: fixed;
            bottom: -50px;
            z-index: 500;
            width: 100%;
            box-shadow: 0 0 3px #ccc;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
            z-index: 10;

            &.active {
                opacity: 1;
                visibility: visible;
                bottom: 0;
                z-index: 400;
            }

            .item {
                font-size: 16px;
                color: #000;
                border-bottom: 1px solid #dcdee3;
                padding: 15px 0;
                cursor: pointer;

                a {
                    display: flex;
                    align-items: center;

                    small {
                        color: #666;
                        font-size: 11px;
                        margin-right: 4px;
                    }

                    i {
                        margin-left: 4px;
                    }

                    &:hover {
                        color: $primary !important;
                    }
                }

                &:last-child {
                    border: none;
                }
            }
        }
    }

    .main-info {
        >.card-body {
            padding: 20px 30px;

            .no-inventory {
                display: block;
                width: 201px;
                background: #eee;
                text-align: center;
                border-radius: 5px;
                font-size: 18px;
                font-weight: bold;
                padding: 5px 0;
                margin-bottom: 20px;
            }

            .title-code {
                display: flex;
            }

            .product-title {
                font-size: 16px;
                color: #333;
                line-height: 28px;
                padding-top: 5px;
            }

            .options-dropdown {
                --bs-gutter-y: 0.5rem;
                margin-top: 0.5rem;

                .item {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .title {
                        margin-left: 10px;
                        max-width: 50px;
                        width: 100%;
                    }

                    .dropdown {
                        width: calc(100% - 50px - 10px);

                        .dropdown-toggle {
                            background: transparent;
                            border: 1px solid #BDBDBD;
                            display: flex;
                            align-items: center;
                            width: 100%;

                            span {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &:after {
                                margin-right: auto;
                            }
                        }

                        .dropdown-menu {
                            width: 100%;
                            padding-bottom: 0;

                            .search-holder {
                                padding: 0.25rem 1rem 0.5rem;

                                .form-control.form-control-solid {
                                    background-color: #f0f0f1 !important;
                                }
                            }

                            .items {
                                max-height: 303px;
                                overflow: auto;
                            }

                            .no-data {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 15px;
                                height: 200px;
                            }

                            .dropdown-item {
                                font-size: 13px;
                                text-align: right;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                &.color-dropdown {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                }

                                .color-box {
                                    width: 20px;
                                    height: 20px;
                                    background: var(--background);
                                    border-radius: 5px;
                                    margin: 0;
                                }

                                &.active,
                                &:active {
                                    background-color: #efefef;
                                }
                            }
                        }
                    }
                }
            }

            .infos {
                margin: 30px 0px;

                .item {
                    font-size: 12px;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;

                    small {
                        line-height: 20px;
                        color: #828282;
                    }
                }
            }

            .boxed-icon {
                background-color: $gray-200;
                padding: 5px 5.5px;
                border-radius: 50%;
                cursor: pointer;
                font-size: 13px;
            }

            .catalogue {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(180deg, $primary, $dark-primary);
                border-radius: 4px;

                a {
                    color: $white !important;
                }
            }

            .count-box {
                display: flex;
                align-items: center;
                border: 1px solid #BDBDBD;
                border-radius: 4px;
                padding: 15px 8px;

                .counter {
                    display: flex;
                    align-items: center;

                    span {
                        width: 30px;
                        font-size: 16px;
                    }
                }

                p {
                    width: 100%;
                    text-align: center;
                }
            }

            .delivery-box {
                border-color: #BDBDBD !important;

                .card-body {
                    display: flex;

                    img {
                        width: 38px;
                        margin-left: 4px;
                    }

                    small {
                        line-height: 20px;
                    }
                }
            }

            .shopping-btn {
                color: #fff;
                text-align: center;
                font-size: 15px;
                position: relative;
                padding: 15px;
                border-radius: 5px;
                cursor: pointer;
                background-color: $primary;
                background-image: linear-gradient(180deg, $primary, $dark-primary);
                border: 1px solid rgba(0, 0, 0, .25);
                box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 25%), 0 2px 2px rgb(0 0 0 / 17%);
                letter-spacing: .5px;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 55px;
                    height: 100%;
                    text-align: center;
                    line-height: 47px;
                    background: $dark-primary;
                }

                &.blue {
                    background-color: #028bd6;
                    background-image: none;

                    span {
                        background: #0482c7;
                    }

                    &:hover {
                        background-color: #0482c7;

                        span {
                            background: #028bd6;
                        }
                    }
                }

                &:hover {
                    background: $dark-primary;

                    span {
                        background: $primary;
                    }
                }
            }

            .features {
                display: flex;
                flex-flow: column;
                align-items: flex-end;

                div {
                    margin-bottom: 30px;
                    width: 34px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    border: 1px solid transparent;
                    cursor: pointer;

                    i {
                        font-size: 18px;
                        color: #a5a5a5;
                    }

                    &:hover {
                        border: 1px solid #E0E0E0;
                        background-color: #F2F2F2;

                        i {
                            color: #111;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .sellers {
        .item {
            width: 100%;

            tbody {
                tr {
                    border-bottom: 1px solid $border-color;
                    cursor: pointer;

                    td {
                        padding: 20px 15px;
                    }

                    &:last-child {
                        border: none;
                    }
                }
            }

            .selected-seller {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid $gray-300;
                position: relative;
                margin-left: 10px;

                &.active {
                    &::before {
                        content: "";
                        height: 8px;
                        width: 8px;
                        background: $primary;
                        border-radius: inherit;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }

    .image-gallery {
        .desktop {
            display: flex;

            .image-items {
                .item {
                    border-radius: 10px;
                    border: solid 1px #cecece;
                    margin-bottom: 10px;
                    position: relative;
                    cursor: pointer;
                    height: 70px;
                    width: 70px;

                    img {
                        filter: opacity(60%);
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                    }

                    &.more {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &::after {
                            width: 30px;
                            height: 30px;
                            content: "...";
                            position: absolute;
                            background-color: rgba(0, 0, 0, 0.45);
                            border-radius: 15px;
                            color: #fff;
                            font-size: 24px;
                            line-height: 20px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }
            }

            .selected-image-holder {
                width: 357px;
                height: 275px;
                display: block;
                margin: auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .mobile {
            .swiper-container {
                padding-bottom: 35px;

                .swiper-pagination {
                    width: initial !important;
                    bottom: 0;
                }

                .swiper-wrapper {
                    .swiper-slide {
                        img {
                            margin: 0 auto;
                            display: block;
                            height: 340px;
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }

    .main-image-holder {
        .main-image {
            padding-bottom: 90%;
            position: relative;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .why-us {
        .card {
            .card-body {
                padding: 15px;
            }
        }

        .item {
            display: flex;
            flex-flow: column;
            align-items: center;

            &.link {
                height: 100%;
                padding: 15px 0px;
                background-color: #DCDEE3;
                border: 1px solid #cecece;
                border-radius: $border-radius;

                span {
                    background-color: $white;
                }

                p {
                    color: $black;
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                background: $gray-100;
                border-radius: 50%;
                font-size: 30px;
                color: #A5ACC7;
                width: 60px;
                height: 60px;
                line-height: 60px;
                margin-bottom: 5px;
            }

            p {
                line-height: 22px;
                color: $text-muted;
            }
        }
    }

    .sticky-sidebar-holder {
        margin-top: 30px;

        .sidebar-product {
            border-color: #E0E0E0 !important;

            .card-body {
                padding: 30px 20px;

                img {
                    width: 100%;
                }

                .title {
                    font-size: 15px;
                    line-height: 27px;
                    margin: 30px 0 !important;
                }

                .buy-btn {
                    background: linear-gradient(180deg, $primary 0%, $dark-primary 99.99%, rgba(223, 57, 80, 0) 100%);
                    border-radius: 5px;
                    height: 56px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 56px;
                    text-align: center;
                    letter-spacing: -0.04em;
                    color: #fff;
                    margin-top: 30px;
                    width: 100%;
                    display: block;
                    position: static;
                    box-shadow: none;
                    border: 0;
                    padding: 0;
                    cursor: pointer;
                }
            }
        }

        .all-infos {
            nav {
                background-color: #DCDEE3 !important;
                border-radius: $border-radius;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                box-shadow: 0 2px 6px 0 rgb(0 0 0 / 6%);
                padding: 15px 20px 0;
                z-index: 2;

                button {
                    padding: 0 15px 5px;
                    color: #656565;
                    cursor: pointer;
                    background-color: transparent;
                }
            }

            .item {
                padding: 20px 20px 0;
                background-color: $white;

                .content {
                    border-bottom: 1px solid #e4e4e5;
                    padding-bottom: 20px;

                    .title {
                        margin-bottom: 15px !important;
                        display: flex;
                        align-items: center;
                        color: #00a7ff;

                        &::before {
                            content: '';
                            border: 2px solid #4e5b89;
                            width: 11px;
                            height: 11px;
                            border-radius: 50%;
                            margin-left: 4px;
                        }
                    }

                    .right-space {
                        padding-right: 15px;
                    }

                    * {
                        line-height: 25px;
                    }

                    img {
                        margin: 10px auto;
                        display: block;
                    }
                }

                &:last-child {
                    .content {
                        border: none;
                    }
                }

                .general-specifications {
                    table {
                        width: 100%;

                        tbody {
                            tr {
                                td {
                                    padding: 5px;
                                    line-height: 21px;
                                    font-size: 12px;

                                    &:first-child {
                                        width: 25%;
                                    }
                                }
                            }
                        }
                    }
                }

                .buyers-comment {
                    .comment {
                        padding-bottom: 12px;
                        margin-bottom: 12px;
                        border-bottom: 1px solid #e4e4e5;
                        display: flex;
                        flex-flow: column;
                        gap: .5rem;

                        &:last-of-type {
                            padding: 0;
                            margin: 0;
                            border: none;
                        }

                        .info {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                        }
                    }
                }

                .new-question-comment {
                    font-weight: bold !important;
                    width: 130px;
                    display: block;
                    margin-right: auto;
                    margin-top: -25px;
                }

                .question-answers {
                    .question-box {
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .question {
                            margin-bottom: 15px;
                            padding-top: 15px;
                            margin-right: 2%;
                            display: flex;

                            i {
                                color: #00a7ff;
                                font-size: 18px;
                                padding-left: 10px;
                            }
                        }

                        .answer {
                            margin-right: 6%;
                        }
                    }
                }

                .price-chart {
                    background-color: $white;

                    .apexcharts-tooltip {
                        background: transparent;
                        border-radius: 2px;
                        backdrop-filter: blur(2px);
                        border-top: 1px solid rgba(0, 143, 251, 0.27);
                        border-left: 1px solid rgba(0, 143, 251, 0.27);

                        .apexcharts-tooltip-title {
                            background: transparent !important;
                            border: none !important;
                            margin: 0 !important;
                            line-height: normal;
                        }

                        .apexcharts-tooltip-series-group {
                            padding: 0px !important;

                            .apexcharts-tooltip-marker {
                                display: none;
                            }

                            .apexcharts-tooltip-text {
                                width: 100%;

                                .apexcharts-tooltip-y-group {
                                    padding: 6px !important;
                                    display: flex;
                                    flex-flow: row-reverse;
                                    align-items: center;

                                    .apexcharts-tooltip-text-y-label {
                                        line-height: normal;
                                    }

                                    .apexcharts-tooltip-text-y-value {
                                        font-size: 14px;
                                        line-height: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .main-info {
            margin-top: 45px;
            margin-left: -12px;
            margin-right: -12px;

            >.card-body {
                padding: 15px;

                .no-inventory {
                    margin-bottom: 30px;
                }

                .title-code {
                    top: -25px;
                    position: relative;
                }
            }

            .shopping-btn-holder {
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 17px;
                background: white;
                box-shadow: 0px -1px 15px rgb(0 0 0 / 25%);
                z-index: 3;
                padding-right: 97px;
            }
        }

        .sticky-sidebar-holder {
            .all-infos {
                .accordion {
                    .accordion-item {
                        .accordion-header {
                            .accordion-button {
                                color: #00a8fd !important;
                                font-size: 11px !important;
                                font-weight: bold;
                            }
                        }
                    }
                }

                .item {
                    padding: 15px;
                    background-color: #f8f8f8;

                    .content {
                        border: none;
                        padding: 0;

                        .title {
                            display: none;
                        }
                    }

                    .new-question-comment {
                        margin-top: 0;
                    }
                }
            }
        }

        .main-image-holder {
            margin-bottom: 35px;
        }
    }
}

#image-gallery-modal {
    @media only screen and (min-width: 576px) and (max-width: 992px) {
        max-width: 800px;
    }

    .modal-content {
        border-radius: 0px;
        border: none;

        .modal-header {
            border-radius: 0px;
            padding: 10px 15px 3px;

            .btn-close {
                padding: 0;
                margin-left: 0;
            }

            small {
                color: $black;
                position: relative;
                padding: 13px 17px;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -3px;
                    height: 3px;
                    background-color: $black;
                }
            }
        }

        .modal-body {
            .desktop {
                .image-items {
                    display: flex;
                    gap: 7px;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .item {
                        height: 75px;
                        min-width: 75px;

                        img {
                            border: 1px solid #cdcdcd;
                            filter: opacity(60%);
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                            border-radius: 10px;
                        }
                    }
                }

                .selected-image-holder {
                    >div {
                        display: block;
                        margin: auto;
                        cursor: crosshair;
                    }
                }
            }

            .mobile {
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: space-between;

                .mySwiper2 {
                    margin-top: 30px;
                    width: 100%;

                    .swiper-slide {
                        .img-box {
                            width: 100%;
                            height: 50vh;

                            img {
                                object-fit: contain;
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }

                .mySwiper {
                    .swiper-slide {
                        width: 100px !important;

                        &.swiper-slide-thumb-active {
                            img {
                                filter: opacity(100%);
                            }
                        }

                        img {
                            border: 1px solid #cdcdcd;
                            filter: opacity(60%);
                            object-fit: cover;
                            height: 100px;
                            width: 100%;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}

#new-question-comment-modal {
    .modal-content {
        border-radius: 10px;

        .modal-header {
            padding: 15px 35px 0;
            display: flex;
            flex-flow: column;
            align-items: flex-start;

            .btn-close {
                position: absolute;
                top: 15px;
                right: 15px;
            }

            .extra-info {
                small {
                    margin-top: 15px !important;
                }
            }
        }

        .modal-body {
            padding: 35px 35px 20px;
        }

        .input-holder {
            display: flex;
            align-items: center;
            position: relative;

            .icon-holder {
                position: absolute;
                left: 0.75rem;
                display: flex;

                i {
                    font-size: 15px;
                    cursor: pointer;
                }
            }
        }

        .opinion {
            margin-top: 10px;
            * {
                line-height: 25px;
            }

            p {
                display: flex;
                gap: 0.25rem;

                span {
                    width: 100%;
                }
            }
        }
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(16, 172, 132, 0.5);
        box-shadow: 0 0 0 0 rgba(16, 172, 132, 0.5);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(16, 172, 132, 0);
        box-shadow: 0 0 0 10px rgba(16, 172, 132, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(16, 172, 132, 0);
        box-shadow: 0 0 0 0 rgba(16, 172, 132, 0);
    }
}

@keyframes moveTop {
    from {
        bottom: 0;
    }
    to {
        bottom: 10px;
    }
}

@keyframes pulse2 {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 165, 0, 0.5);
        box-shadow: 0 0 0 0 rgba(0, 165, 0, 0.5);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(0, 165, 0, 0);
        box-shadow: 0 0 0 10px rgba(0, 165, 0, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 165, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 165, 0, 0);
    }
}

@keyframes animateScale {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}

@keyframes animateRotate {
    0% {
        transform: rotate(20deg);
        -webkit-transform: rotate(20deg);
    }
    50% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100% {
        transform: rotate(-20deg);
        -webkit-transform: rotate(20deg);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
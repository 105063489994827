#faq {
    .card {
        .card-body {
            padding-top: 40px;
            padding-bottom: 40px;
            .holder {
                padding: 0px 30px 50px;
                .question {
                    font-size: 16px;
                    font-weight: 700;
                    color: $primary;
                    margin-bottom: 15px !important;
                    line-height: 20px;
                }
                .answer {
                    line-height: 20px;
                    text-align: justify;
                }
            }
        }
    }
}
.swiper-container {
    .swiper-pagination {
        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            border: 1px solid $gray-500;
            margin: 0px 2px !important;
        }
        .swiper-pagination-bullet-active {
            background-color: $gray-500;
            width: 10px;
            height: 10px;
        }
    }
}
#auth {
    .container {
        .card {
            border-radius: 10px !important;

            .card-body {
                padding: 30px 50px;

                .logo {
                    position: absolute;
                    left: 50px;
                    top: 30px;
                    height: 30px;

                    img {
                        height: inherit;
                    }
                }

                .auth-image {
                    margin-top: 120px;
                }

                .before-title {
                    padding-top: 0;

                    p {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        .before-title {
            p {
                font-size: 18px;
            }
        }
    }

    @media (max-width: 768px) {
        .container {
            background-color: red;
            padding: 0px;
            max-width: 100% !important;

            .card {
                min-height: 100vh;
                border-radius: 0px !important;

                .card-body {
                    padding: 30px;
                }
            }
        }
    }

    @media (max-width: 576px) {
        .container {
            .card {
                .card-body {
                    .skip-btn {
                        width: 100%;
                    }
                }
            }
        }
    }
}
#blog {
    background: $white;
    min-height: 100vh;

    .text-shadow {
        text-shadow: 2px 2px 5px rgb(0 0 0 / 40%);
    }

    .topic-title {
        color: rgb(38, 94, 150);
    }

    .fix-box {
        position: fixed;
        background: rgba($color: #0b0b0b, $alpha: 0.85);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: $white;
        overflow: auto;

        &.active {
            display: flex;
        }

        .close-icon {
            position: fixed;
            left: 1rem;
            top: 1rem;
            font-size: 20px;
        }

        .content {
            .input-holder {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                input {
                    background-color: rgba(255, 255, 255, .2) !important;
                    border-color: rgba(255, 255, 255, .09) !important;
                    border-radius: 99px !important;
                    padding: 0 0.75em;
                    height: 2.507em;
                    font-size: 27px !important;
                    color: $white;
                }

                .icon-holder {
                    position: absolute;
                    left: 23px;

                    i {
                        font-size: 25px;
                        color: $white;
                    }
                }
            }

            .item {
                padding: 10px 0.75em;
                display: flex;
                align-items: center;

                img {
                    width: 40px;
                    border-radius: 99px;
                    height: 40px;
                    display: inline-block;
                    margin-right: 10px;
                }

                p {
                    font-size: 27px;
                    color: $white;
                }
            }
        }
    }

    #header {
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            max-width: 200px;
            max-height: 56px;
        }

        .back-to-app {
            font-size: 1.15em !important;
            font-weight: bold;
            border-radius: 16px;
            border-width: 2px;
            padding: 7px 24px !important;
        }

        .mobile-menu {
            i {
                font-size: 1.9em;
            }
        }

        .socials {
            a {
                i {
                    font-size: 1.9em;
                }
            }
        }

        .fix-box {
            overflow-x: hidden;

            .content {
                .search-content {
                    max-width: 260px;
                    margin: auto;
                    padding: 1em 0px;

                    .input-holder {
                        input {
                            height: 37px;
                            font-size: 13px !important;
                            color: $white;
                        }

                        .icon-holder {
                            display: flex;
                            left: 12px;

                            i {
                                font-size: 13px;
                            }
                        }
                    }

                    .search-result {
                        max-height: 50vh;
                        overflow-y: auto;

                        .item {
                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }

                ul {
                    list-style: none;
                    padding: 0px;
                    text-align: center;

                    li {
                        color: $white;

                        a {
                            color: inherit !important;
                            padding: 5px 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &.menu-item {
                            .menu-item-link {
                                padding: 15px 30px;
                                font-size: 25px;
                                font-weight: bold;

                                i {
                                    font-size: 16px;
                                    position: absolute;
                                    left: 30px;
                                    transition: all .3s;
                                }
                            }

                            .children {
                                padding-bottom: 30px;
                                position: fixed;
                                opacity: 0;
                                right: -99999px;
                                transform: translateX(-10px);
                                transition: opacity .3s, transform .3s;
                            }

                            &.active {
                                background-color: rgba(0, 0, 0, .05);

                                a {
                                    i {
                                        transform: rotateZ(180deg);
                                    }
                                }

                                .children {
                                    position: inherit;
                                    opacity: 1;
                                    right: auto;
                                    transform: translateX(0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar {
        padding: 4.5px 0px;
        position: initial;
        width: 100%;

        .navbar-nav {
            display: flex;
            align-items: center;

            .nav-link {
                color: $white !important;
                font-size: 1rem;

                a {
                    color: inherit !important;
                }
            }

            .nav-item {
                .nav-link {
                    display: flex;
                    align-items: center;

                    &::after {
                        margin-right: 4px;
                    }
                }

                .dropdown-menu {
                    padding: 20px 0px 20px;
                    left: initial;
                    right: 0;
                    border-radius: 0px;
                    border: 2px solid #ddd;
                    min-width: 260px;

                    &::before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 11px solid transparent;
                        border-right: 11px solid transparent;
                        border-bottom: 13px solid #ddd;
                        position: absolute;
                        top: -13px;
                        right: 30px;
                    }

                    &::after {
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 11px solid transparent;
                        border-right: 11px solid transparent;
                        border-bottom: 13px solid $white;
                        position: absolute;
                        top: -10px;
                        right: 30px;
                    }

                    .dropdown-item {
                        text-align: right;
                        padding: 10px 20px;
                    }
                }
            }

            .search-icon {
                font-size: 1rem;
                color: $white;
            }
        }

        .socials {
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;

                i {
                    font-size: 1.9em;
                }
            }
        }
    }

    .sidebar-holder {
        border-right: 1px solid $border-color;

        .sidebar {
            .categories {
                .item {
                    padding: 8px 9px;
                    margin-bottom: 8px;
                    border-radius: 10px;
                    color: #fff !important;
                    transition: 0.3s all ease;
                    display: flex;
                    align-items: center;

                    p {
                        line-height: 38px;
                        font-size: 16px;
                    }

                    &:hover {
                        padding-right: 16px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .titled-box {
                .title {
                    font-size: 17px;
                    font-weight: 600;

                    &::after {
                        content: '';
                        height: 3px;
                        display: block;
                        background-color: rgba(0, 0, 0, .1);
                        margin: 10px 0px;
                        width: 30px;
                    }
                }

                .content {
                    .item {
                        display: flex;
                        margin-bottom: 15px;

                        img {
                            width: 75px;
                            height: 75px;
                            border-radius: 6px;
                            margin-left: 10px;
                        }

                        a {
                            font-size: 17px;
                            color: rgb(38, 94, 150) !important;
                        }
                    }
                }
            }

            .last-comments {
                .card {
                    border-radius: 10px !important;

                    .card-body {
                        .title {
                            font-size: 17px;
                            font-weight: 600;
                            margin-bottom: 10px !important;
                        }
                    }
                }
            }
        }
    }

    .topic {
        .item {
            img {
                border-radius: $card-border-radius;
                height: 100%;
            }

            .date {
                position: absolute;
                top: 7px;
                right: 10px;
                width: 50px;
                height: 50px;
                background: $primary;
                color: $white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                flex-flow: column;
                justify-content: center;

                .day {
                    line-height: 15px;
                }

                .month {
                    line-height: 12px;
                }
            }

            .text-box {
                padding: 10px 10px;
                display: flex;
                flex-flow: column;
                justify-content: center;
                height: 100%;

                .title {
                    color: rgb(38, 94, 150);
                    font-size: 17px;
                    font-weight: bold;
                    margin-bottom: 10px !important;
                }

                .desc {
                    font-size: 15px;
                    height: 22px;
                    overflow: hidden;
                }
            }

            &:hover {
                .text-box {
                    .desc {
                        color: $primary;
                    }
                }
            }
        }
    }

    .post {
        .card {
            box-shadow: 0 1px 3px -2px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);

            .card-body {

                header,
                .content {
                    padding: 1.5em;
                }

                header {
                    .breadcrumb {
                        margin-bottom: 10px;

                        p {
                            font-size: 11px;

                            a {
                                &:hover {
                                    color: $primary !important;
                                }

                                &::after {
                                    content: "،";
                                }
                            }
                        }
                    }

                    h3 {
                        line-height: 1.7em;
                        font-weight: bold;

                        &::after {
                            content: "";
                            height: 3px;
                            display: block;
                            background-color: rgba(0, 0, 0, 0.1);
                            margin: 10px 0px;
                            width: 30px;
                        }
                    }
                }

                .img-box {
                    position: relative;

                    .date {
                        position: absolute;
                        top: 7px;
                        right: 10px;
                        width: 50px;
                        height: 50px;
                        background: $primary;
                        color: $white;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        flex-flow: column;
                        justify-content: center;

                        .day {
                            line-height: 15px;
                        }

                        .month {
                            line-height: 12px;
                        }
                    }
                }

                img {
                    width: 100%;
                }

                .content {
                    padding-top: 4px;
                    display: flex;
                    flex-flow: column;

                    .reading-time {
                        background: $primary;
                        padding: 4px 20px 4px 9px;
                        margin-right: calc(-1.5em - 5px);
                        color: $white;
                        margin-bottom: 15px;
                        font-size: 15px;
                        position: relative;

                        &::after {
                            content: "";
                            border-top: 24px solid $primary;
                            border-left: 8px solid transparent;
                            position: absolute;
                            left: -8px;
                            top: 0;
                        }
                    }

                    .text {
                        p {
                            margin-bottom: 10px !important;
                            font-size: 16px;

                            a {
                                color: $primary !important;
                            }

                            img {
                                display: block;
                                margin: auto;
                            }
                        }
                    }

                    .share-section {
                        background: #f7f7f7;
                        padding: 10px 12px;
                        border-radius: 12px;
                        width: 100%;
                        display: flex;
                        flex-flow: column;
                        align-items: flex-start;
                        margin-top: 16px;

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .container {
            max-width: 100% !important;
        }
    }

    @media (max-width: 768px) {
        .sidebar-holder {
            border: none;
        }
    }
}

.blog-item {
    img {
        border-radius: $card-border-radius;
    }

    .text-box {
        padding-top: 3px;
        padding-bottom: 10px;
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        * {
            line-height: 30px;
        }

        .category {
            background-color: $primary;
            padding: 3px 7px;
            border-radius: $border-radius;
            color: $white;
        }

        .title {
            padding-top: 5px;
            font-size: 17px;
            font-weight: bold;
        }

        .date {
            font-size: .8em;
            line-height: 22px;
        }

        .desc {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical
        }
    }

    &:hover {
        .text-box {

            .desc,
            .date {
                color: $primary;
            }
        }
    }
}
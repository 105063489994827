#dashboard {
    display: flex;
    flex-flow: column;
    gap: 30px;

    .swiper-container {
        .swiper-pagination {
            .swiper-pagination-bullet {
                width: 11px;
                height: 11px;
                border: 1px solid $white;
                margin: 0px 4px !important;
            }

            .swiper-pagination-bullet-active {
                background-color: $white;
                width: 13px;
                height: 13px;
            }
        }

        @media (max-width: 576px) {
            .swiper-pagination {
                display: none;
            }
        }
    }

    .main-slider {
        .swiper-container {
            .swiper-slide {
                img {
                    width: 100%;
                    border-radius: 12px;
                }
            }
        }

        &.fix {
            .swiper-container {
                .swiper-slide {
                    a {
                        display: flex;
                        height: 450px;
                        width: 100%;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }

    .middle-banner {
        .item {
            border-radius: 3px;

            a {
                position: relative;
                display: inline-block;
            }

            img {
                width: 100%;
                height: auto;
                border-radius: 3px;
            }

            .home-page-slider-text {
                position: absolute;
                width: 100%;
                color: $white;
                padding: 7px 3% 9px 3%;
                line-height: 1.5em;
                text-align: right;
            }

            &.banner {
                .home-page-slider-text {
                    bottom: 0;
                    right: 0;

                    P {
                        &:first-child {
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            font-size: 1rem;
                        }

                        &:last-child {
                            font-size: 0.6rem;
                        }

                        i {
                            margin-right: 7px;
                            font-size: 16px;
                            display: inline-table;
                        }
                    }
                }
            }

            &.sell-to {
                .home-page-slider-text {
                    text-align: left;
                    top: 0;
                    left: 0;

                    >p {
                        margin-right: auto !important;
                        width: 150px;
                        font-size: 1rem;
                    }

                    div {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 10px;

                        a {
                            display: flex;
                            align-items: center;
                            padding: 4px 6px;
                            border-radius: 30px;
                            border: 1px solid $white;
                            font-size: 0.6rem;
                            color: $white !important;

                            i {
                                margin-right: 6px;
                                font-size: 11px;
                            }
                        }
                    }
                }
            }

            @media (max-width: 992px) {}
        }

        .mobile-category-site {
            display: none;
            background: #fff;
            padding-top: 15px;
            padding-bottom: 15px;

            a {
                display: flex;
                flex-flow: column;
                align-items: center;

                .mobile-category-site-title {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    letter-spacing: -0.5px;
                    line-height: 16px;
                    text-align: center;

                    .br {
                        display: none;
                    }
                }

                img {
                    width: 55px;
                    height: 55px;
                    object-fit: contain;
                }
            }

            @media (max-width: 576px) {
                display: flex;

                a {
                    .mobile-category-site-title {
                        margin-bottom: 0;
                        margin-top: 6px;
                    }
                }
            }

            @media (max-width: 380px) {
                a {
                    .mobile-category-site-title {
                        font-size: 13px;

                        .br {
                            display: block;
                        }
                    }
                }
            }

            @media (max-width: 359px) {
                a {
                    .mobile-category-site-title {
                        font-size: 11px;
                    }
                }
            }
        }
    }

    .most-popular {
        .card {
            .card-body {
                padding: 40px;

                .item {
                    text-align: center;
                    border-radius: 2.5px;

                    a {
                        .image-box {
                            border: 1px solid #efefef;
                            border-radius: 2.5px;
                            padding: 20px 9px 15px;
                            margin-bottom: 15px;
                            position: relative;
                            width: 100%;
                            height: 138px;

                            img {
                                height: 100px;
                                object-fit: contain;
                                width: 100%;
                            }
                        }

                        &.move-up-animate:hover {
                            .image-box {
                                animation: moveTop 0.3s forwards;
                                color: $primary;
                                border: 1px solid $primary !important;
                                box-shadow: 0 3px 5px $dark-primary;
                            }
                        }

                        p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            height: 39px;
                        }
                    }
                }
            }
        }

        @media (max-width: 576px) {
            .mostpopular-slider-box {
                padding: 30px 10px 30px 0px;
            }

            .card {
                .card-body {
                    padding: 25px;

                    .item {
                        a {
                            .image-box {
                                border: none;
                                padding: 0px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .bannered-product {
        padding: 30px 0px 5px;

        .desktop-banner {
            margin-bottom: 25px;

            a {
                display: flex;
                height: 100%;
                position: relative;

                img {
                    width: 100%;
                }

                .homeBanerText {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 100%;
                    text-align: right;
                    padding: 7px 8% 9px 8%;
                    color: #000;
                    line-height: 1.5em;

                    p {
                        &:first-child {
                            font-size: 1rem;
                            font-weight: bold;
                            margin-bottom: 30px;
                            line-height: 25px;
                            letter-spacing: -0.5px;
                            word-spacing: -0.8px;
                        }

                        &:last-child {
                            font-size: 0.9rem;
                            border: 1px solid #000;
                            border-radius: 15px;
                            padding: 5px 5px 5px 0;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            i {
                                font-size: 0.7rem;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }

        .mobile-banner {
            margin-bottom: 25px;

            a {
                display: flex;
                align-items: flex-end;
                position: relative;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .homeBanerText {
                    position: absolute;
                    padding: 10px;
                    color: $white;

                    >p {
                        font-size: 1rem;
                        line-height: 21px;
                        margin-bottom: 10px;
                    }

                    div {
                        display: flex;
                        justify-content: flex-start;

                        p {
                            display: flex;
                            align-items: center;
                            padding: 5px 8px;
                            border: 1px solid;
                            border-radius: 20px;
                            font-size: 0.7rem;

                            span {
                                line-height: normal;
                            }

                            i {
                                font-size: 11px;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }

        .item-box {
            margin-bottom: 25px;

            a {
                background: $white;
                text-align: center;
                padding: 20px;
                display: block;
                height: 100%;

                &:hover {
                    animation: moveTop 0.3s forwards;
                    color: #e1384d;
                    border: 1px solid $primary;
                    box-shadow: 0 3px 5px $dark-primary;
                    position: relative;
                }

                img {
                    width: 100%;
                }

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    height: 39px;
                    margin-top: 30px;
                }
            }
        }

        .mobile-box {
            padding-right: 10px;

            .swiper-slide {
                .item-box {
                    height: 182px;
                }
            }
        }

        @media (max-width: 1500px) {
            .desktop-box {
                .desktop-banner {
                    a {
                        .homeBanerText {
                            p {
                                &:last-child {
                                    font-size: 0.7rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1300px) {
            .desktop-box {
                .desktop-banner {
                    a {
                        .homeBanerText {
                            p {
                                &:last-child {
                                    font-size: 0.6rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            .desktop-box {
                .desktop-banner {
                    a {
                        align-items: center;

                        .homeBanerText {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            left: 36%;
                            right: 13px;
                            padding: 0;
                            top: initial;
                            width: initial;

                            p {
                                &:first-child {
                                    font-size: 1.3rem;
                                    margin-left: 10px;
                                    margin-bottom: 0;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                &:last-child {
                                    font-size: 0.8rem;
                                    padding: 5px;
                                    min-width: max-content;

                                    i {
                                        font-size: 0.7rem;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 992px) {
            .desktop-box {
                .desktop-banner {
                    a {
                        .homeBanerText {
                            p {
                                &:first-child {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .item-box {
                a {
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;

                    p {
                        margin-top: 0;
                    }
                }
            }
        }

        @media (max-width: 576px) {
            padding: 30px 0px;

            .item-box {
                a {
                    img {
                        max-width: 130px;
                    }

                    p {
                        margin-top: 30px;
                    }
                }
            }

            .mobile-banner {
                a {
                    img {
                        width: 100%;
                        height: 182px;
                    }
                }
            }
        }
    }

    .big-banner {
        // padding: 30px 0px 5px;
    }

    .ad-banners {
        // padding: 30px 0px 5px;

        .row {
            row-gap: 25px;
        }
    }

    // .blog-box {
    //     padding: 30px 0 5px;
    // }

    .before-title {
        padding-top: 0;
    }
}
#contact-us {
    .card {
        .card-body {
            padding: 40px;
            .text-content {
                margin-bottom: 40px;
                .title {
                    margin-bottom: 10px !important;
                    line-height: 20px;
                    font-weight: bold;
                }
                p {
                    line-height: 21px;
                }
                .contact-phone {
                    font-size: 22px;
                    margin: 10px 0;
                    display: inline-block;
                }
            }
            @media (max-width: 768px) {
                form {
                    padding-top: 15px;
                    border-top: 1px solid $border-color;
                }
            }
        }
    }
}
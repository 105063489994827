.btn {
    position: relative;
    font-size: 14px !important;
    padding: 0.5rem 0.75rem !important;
    box-shadow: none !important;
    &.btn-sm {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        font-size: 12px !important;
    }
    &.btn-lg {
        font-size: 18px !important;
        font-weight: bold;
    }
    &.pill {
        border-radius: 50px !important;
    }
    &.btn-outline-secondary {
        color: $black;
    }
}

.spinner-holder {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px);
}